<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'

/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters
const filter_orders = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const orders = ref()
const bank_accounts = ref()
const products = ref()
const fictional_users = ref()
const company_info = ref()
const salutations = ref()
const languages = ref()
const get_base_data = () => {
    // Get Data
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/get-all-bexio-orders')
    .then(response => {
        orders.value = response.data
        })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/get-bank-accounts')
    .then(response => {
        bank_accounts.value = response.data
        })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/get-all-bexio-products')
    .then(response => {
        products.value = response.data
        })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/get-fictional-users')
    .then(response => {
        fictional_users.value = response.data
        })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/get-bexio-company')
    .then(response => {
        company_info.value = response.data
        console.log(response.data)
        })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/bexio-salutations')
    .then(response => {
        salutations.value = response.data
    })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/bexio-languages')
    .then(response => {
        languages.value = response.data
    })
}
onMounted(() => {
    get_base_data()
})
/////////////////
// Form Fields //
/////////////////

const notes_data = ref({
    note_date: {
        name: "Datum",
        required: true,
        validation: "text",
        message: "Bitte Datum auswählen",
        value: "",
        error: false,
        active: true,
        unique: false
    },
    subject: {
        name: "Betreff",
        required: true,
        validation: "text",
        message: "Bitte Betreff ausfüllen",
        value: "",
        error: false,
        active: true,
        unique: false
    },
    info: {
        name: "Details",
        required: true,
        validation: "text",
        message: "Bitte Details ausfüllen",
        value: "",
        error: false,
        active: true,
        unique: false
    }
})
const edit = ref({
    "rates": null,
    "invoice_amount": null,
    "delivery_date": null,
    "bank_account": null,
    "bexio_id": null,
    "order_name": null,
    "language": null,
    "total_difference": null,
    "product_name": null
})
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// CRUD Logic //////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const invoices = ref()
const notes = ref()
const showInvoices = ref(false)
const showNotes = ref(false)
const createNote= ref(false)

const contact = ref()
const notes_contact_id = ref()
const rates = ref()
const rates_total = ref()
const rates_count = ref()
const rates_position = ref()
const rates_contact = ref()
const createInvoiceDialog = ref(false)
const cancel_order_id = ref()
const cancelOrderDialog = ref(false)
const stallOrderDialog = ref(false)
const unStallOrderDialog = ref(false)
const showStatementDialog = ref(false)
const editCustomerDialog = ref(false)
const cancel_reason = ref()
const cancel_date = ref()
const contact_groups = ref([])
const bank_account = ref()
const rep = ref()

const count_rates = ref()
const count_rates_paid = ref()
const count_rates_unpaid = ref()
const total_invoices = ref()
const total_paid = ref()
const total_unpaid = ref()
const total_cash_discounts = ref(0)
const count_cash_discounts = ref()
const count_unpaid_overdue = ref()
const total_invoices_overdue = ref()
const language = ref()
const product = ref()
const order_date = ref()
const registration_date = ref()

const details_heading = ref("")

const get_invoices = (e) => {
    loader.value = true
    let payload = {"contact_id": e.bexio_customer_id}
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/search-contact-invoices", payload)
        .then(response => {
            console.log("search_invoices", response.data)
            invoices.value = response.data
            total_cash_discounts.value = get_total_invoices_cash_discount(invoices.value) + get_total_invoices_skonto_discount(invoices.value)
            count_cash_discounts.value = count_total_invoices_cash_discount(invoices.value)
            count_rates.value = invoices.value.length
            count_rates_paid.value = get_total_invoices_paid(invoices.value)
            count_rates_unpaid.value = count_rates.value - count_rates_paid.value

            total_invoices.value = e.invoices_total
            console.log("total_paid", e.paid_total, total_cash_discounts.value)
            total_paid.value = Number(e.paid_total) - Number(total_cash_discounts.value)
            total_unpaid.value = e.unpaid_total

            order_date.value = format_swiss_date(e.order_date)
            registration_date.value = format_swiss_date(e.registration_date)

            if (e.language == 1){
                language.value = "Deutsch"
            }
            if (e.language == 2){
                language.value = "Französisch"
            }
            product.value = e.product

            count_unpaid_overdue.value = get_total_unpaid_overdue(invoices.value)
            total_invoices_overdue.value = get_total_invoices_overdue(invoices.value)
            rep.value = get_rep(invoices.value)
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/get-contact", payload)
                .then(response => {
                    contact.value = response.data
                    let birthday = format_swiss_date(contact.value["birthday"])
                    let age = difference_in_day(contact.value["birthday"])
                    age = Math.trunc(age / 365)
                    details_heading.value = contact.value.nr + ' - ' + contact.value.name_1 + ' ' + contact.value.name_2 + ' - ' + String(product.value) + ' - ' + contact.value.address + ', ' + contact.value.postcode + ' ' + contact.value.city + ' (' + birthday + ' - ' + String(age) + ' Jahre alt)'
                    showInvoices.value = true
                    loader.value = false
                })
            console.log("invoices", invoices.value)
        })
}

const count_total_invoices_cash_discount = (invoices) => {
    let total_cash_discount = 0
    for (let key in invoices) {
        total_cash_discount += invoices[key].cash_discount_count
    }
    return total_cash_discount
}

const get_total_invoices_cash_discount = (invoices) => {
    let total_cash_discount = 0
    for (let key in invoices) {
        if (Number(invoices[key].cash_discount_total) > 0){
            total_cash_discount += Number(invoices[key].cash_discount_total)
        }
    }
    return total_cash_discount
}

const get_total_invoices_skonto_discount = (invoices) => {
    let total_cash_discount = 0
    for (let key in invoices) {
        for (let key2 in invoices[key]["payments"]){
            if (invoices[key]["payments"][key2].bank_account_id == 9){
                total_cash_discount += Number(invoices[key]["payments"][key2].value)
            }
        }
    }
    return total_cash_discount
}

const status_items = ref([
    {
        label: 'Kontoauszug drucken',
        icon: 'pi pi-fw pi-print',
        command: () => {
            print_bank_statement()
        }
    },
    {
        label: 'Raten',
        icon: 'pi pi-fw pi-file-edit',
        items: [
            {
                label: 'Raten erstellen',
                icon: 'pi pi-fw pi-plus',
                command: () => {
                    create_rates_dialog(contact.value.id)
                }
            },
            {
                label: 'zuv. bez. KG erfassen',
                icon: 'pi pi-fw pi-plus',
                command: () => {
                    create_extra_rate_dialog(contact.value.id)
                }
            }
        ]
    },
    {
        label: 'Bearbeiten',
        icon: 'pi pi-fw pi-user-edit',
        items: [
            {
                label: 'Kundendaten bearbeiten',
                icon: 'pi pi-fw pi-user-edit',
                command: () => {
                    edit_customer_dialog(contact.value.id)
                }
            }
        ]
    },
    {
        label: 'Annulieren / Inkasso',
        icon: 'pi pi-fw pi-ban',
        items: [
            {
                label: 'Auftrag annulieren',
                icon: 'pi pi-fw pi-times',
                command: () => {
                    cancel_order_dialog(contact.value.id)
                }
            },
            {
                label: 'Inkasso',
                icon: 'pi pi-fw pi-ban',
                command: () => {
                    stall_order_dialog(contact.value.id)
                }
            },
            {
                label: 'Inkasso rückgängig',
                icon: 'pi pi-fw pi-replay',
                command: () => {
                    unstall_order_dialog(contact.value.id)
                }
            }
        ]
    },
    {
        label: 'Löschen',
        icon: 'pi pi-fw pi-trash',
        items: [
            {
                label: 'Offene Rechnungen löschen!',
                icon: 'pi pi-fw pi-trash',
                command: () => {
                    delete_unpaid_invoices(contact.value.id)
                }
            },
            {
                label: 'Auftrag & Rechnungen löschen!',
                icon: 'pi pi-fw pi-exclamation-triangle',
                command: () => {
                    delete_order_invoices(contact.value.id)
                }
                
            }
        ]
    }
])


const statement_header = ref()
const get_statement = () => {
    statement_header.value = [{
        "contact_name": contact.value.name_2 + " " + contact.value.name_1,
        "contact_address": contact.value.address,
        "contact_postcode": contact.value.postcode,
        "contact_city": contact.value.city,
        "contact_nr": contact.value.nr,
        "contact_mail": contact.value.mail,
        "contact_language": contact.value.language_id,
        "paid": format_currency(total_paid.value),
        "unpaid": format_currency(total_unpaid.value),
        "unpaid_overdue": format_currency(total_invoices_overdue.value)
    }]
    showInvoices.value = false
    showStatementDialog.value = true
}

const get_fictional_user = (id) => {
    let user_name
    for (let key in fictional_users.value)
        if(fictional_users.value[key].id == id){
            user_name = fictional_users.value[key]["firstname"] + " " + fictional_users.value[key]["lastname"]
        }
    return user_name
}

const print_bank_statement = () => {
    const newWindow = window.open('https://sprachtechnik.snpy.ch/print?template=bank_statement&print_param='+contact.value.id, '_blank', 'width=595,height=842');
    if (newWindow) {
    newWindow.focus();
    }
}
const get_notes = (id, name) => {
    loader.value = true
    let payload = {"contact_id": id}
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/get-notes", payload)
        .then(response => {
            notes.value = response.data
            showNotes.value = true
            loader.value = false
            notes_contact_id.value = id
            note_header.value = "Notizen: " + name
        })
}
const note_header = ref()
const create_note = (id) => {
    createNote.value = true
    notes_data.value.note_date.value = get_swiss_date()
}
const saveNote = (id) => {
    loader.value = true
    let payload = {"contact_id": id}
    let note_payload = {
        "contact_id": id,
        "event_start": swissDate2iso(notes_data.value.note_date.value),
        "subject": notes_data.value.subject.value,
        "user_id": 1,
        "info": notes_data.value.info.value,
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/create-note", note_payload)
        .then(response => {
            createNote.value = false
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/get-notes", payload)
                .then(response => {
                    notes.value = response.data
                    loader.value = false
            })
    })
}
const delete_unpaid_invoices = (id) => {
    confirm.require({
        message: 'Bist du sicher, dass du alle unbezahlten Rechnungen löschen möchtest?',
        header: 'Alle unbezahlten Rechnungen löschen?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, alle Rechnungen löschen!',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            loader.value = true
            let payload = {"contact_id": id}
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/delete-unpaid-invoices", payload)
                .then(response => {
                        invoices.value = response.data
                        loader.value = false
                        showInvoices.value = false
                })
        }
    })
}
const delete_order_invoices = (id) => {
    confirm.require({
        message: 'Bist du sicher, dass du diesen Auftrag & Rechnungen löschen möchtest?',
        header: 'Auftrag & Rechnungen löschen?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Auftrag & Rechnungen löschen!',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            loader.value = true
            let payload = {"contact_id": id}
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/delete-order-invoices", payload)
                .then(response => {
                        invoices.value = response.data
                        loader.value = false
                        showInvoices.value = false
                })
        }
    })
}

const cancel_order_dialog = (id) => {
    confirm.require({
        message: 'Bist du sicher, dass du diesen Auftrag stornieren möchtest? Bitte überprüfe die Rechnungen nach der Annullierung. Die Netto- und Bruttorechnungen werden ebenfalls storniert (sie werden auf dem Durchgangskonto verbucht und müssen ggf. gelöscht werden).',
        header: 'Auftrag annulieren?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Auftrag annulieren!',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            cancel_order_id.value = id
            contact_groups.value = ["13"]
            cancelOrderDialog.value = true
        }
    })
}

const stall_order_dialog = (id) => {
    confirm.require({
        message: 'Bist du sicher, dass du diesen Auftrag auf Inkasso wechseln möchtest? (Rechnungen auf Entwurf setzen)',
        header: 'Auftrag auf Inkasso wechseln?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Auftrag auf Inkasso wechseln!',
        acceptClass: 'p-button-wanring',
        rejectLabel: 'Nein',
        accept: () => {
            cancel_order_id.value = id
            contact_groups.value = ["7"]
            stallOrderDialog.value = true
        }
    })
}

const unstall_order_dialog = (id) => {
    confirm.require({
        message: 'Sind Sie sicher, dass Sie diesen Auftrag vom Inkasso Status zurückziehen wollen?? (Rechnungen auf Offen setzen)',
        header: 'Auftrag vom Inkasso Status zurückziehe?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, vom Inkasso Status zurückziehen!',
        acceptClass: 'p-button-wanring',
        rejectLabel: 'Nein',
        accept: () => {
            cancel_order_id.value = id
            contact_groups.value = []
            unStallOrderDialog.value = true
        }
    })
}


const cancel_order = () => {
    loader.value = true
    let payload = {"contact_id": cancel_order_id.value, "cancel_reason": cancel_reason.value, "contact_groups": contact_groups.value, "cancel_date": swissDate2iso(swissDate(cancel_date.value))}
    cancelOrderDialog.value = false
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/cancel-order", payload)
        .then(response => {
            loader.value = false
            toast.add({ severity: 'success', summary: 'Auftrag erfolgreich annuliert!', detail: 'Der Auftrag wurde erfolgreich annuliert', life: 3000 })
        })
}

const stall_order = () => {
    loader.value = true
    let payload = {"contact_id": cancel_order_id.value, "cancel_reason": cancel_reason.value, "contact_groups": contact_groups.value, "cancel_date": swissDate2iso(swissDate(cancel_date.value))}
    stallOrderDialog.value = false
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/stall-order", payload)
        .then(response => {
            loader.value = false
            toast.add({ severity: 'success', summary: 'Auftrag erfolgreich auf Inkasso gewechselt!', detail: 'Der Auftrag wurde erfolgreich auf Inkasso gewechselt', life: 3000 })
        })
}

const unstall_order = () => {
    loader.value = true
    let payload = {"contact_id": cancel_order_id.value, "cancel_reason": cancel_reason.value, "contact_groups": contact_groups.value}
    unStallOrderDialog.value = false
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/unstall-order", payload)
        .then(response => {
            loader.value = false
            toast.add({ severity: 'success', summary: 'Auftrag erfolgreich vom Inkasso Status zurückgezogen!', detail: 'Der Auftrag wurde erfolgreich vom Inkasso Status zurückgezogen', life: 3000 })
        })
}

const onInvoiceRowEdit = (e) => {
    if(invoices.value[e.index].kb_item_status_id == 9 && e.value != e.newValue){
        toast.add({ severity: 'error', summary: 'Rechnung schon bezahlt!', detail: 'Bezahlte Rechnungen können nicht mehr angepasst werden', life: 3000 })
    }
    if(invoices.value[e.index].kb_item_status_id == 8 && e.value != e.newValue){
        let invoice = invoices.value[e.index]
        invoice[e.field] = e.newValue
        invoices.value[e.index][e.field] = e.newValue
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/bexio-update-invoice", invoice)
            .then(response => {
                if(response.data.message == 'success'){
                    toast.add({ severity: 'success', summary: 'Rechnung angepasst', detail: 'Die Rechnung wurde erfolgreich angepasst', life: 3000 })
                }
            })
    }
}

const create_rates_dialog = (contact_id) => {
    rates.value = [{"total": null, "is_valid_from": get_swiss_date(), "is_valid_to": get_swiss_date()}]
    rates_position.value = ""
    rates_contact.value = contact_id
    rates_count.value = 1
    rates_total.value = 0
    createInvoiceDialog.value = true
    showInvoices.value = false
    console.log("rates", rep.value)
}
const rate_total = ref()
const rate_date = ref()
const rate_title = ref()
const rate_contact = ref()
const createExtraRateDialog = ref(false)
const create_extra_rate_dialog = (contact_id) => {
    rate_total.value = 0
    rate_date.value = null
    rate_title.value = ""
    rate_contact.value = contact_id
    createExtraRateDialog.value = true
}

const create_extra_rate = () => {
    loader.value = true
    let payload = {
        "contact_id": rate_contact.value,
        "total": rate_total.value,
        "is_valid_from": swissDate2iso(swissDate(rate_date.value)),
        "title": rate_title.value,
        "vertreter": rep.value
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/create-extra-rate", payload)
        .then(response => {
            if(response.data.message == 'success'){
                toast.add({ severity: 'success', summary: 'zuv. bez. KG erstellt', detail: 'Die zuv. bez. KG wurde erfolgreich erstellt', life: 3000 })
                createExtraRateDialog.value = false
                loader.value = false
            }
        })
}

const edit_customer_dialog = (contact_id) => {
    loader.value = true
    let payload = {"contact_id": contact_id}
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/get-contact", payload)
    .then(response => {
        contact.value = response.data
        contact.value["birthday"] = format_swiss_date(contact.value["birthday"])
        editCustomerDialog.value = true
        loader.value = false
    })
}

const edit_customer = () => {
    loader.value = true
    let payload = {"contact": contact}
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/edit-contact", payload)
    .then(response => {
        if(response.data.message == 'success'){
            toast.add({ severity: 'success', summary: 'Kundendaten wurden aktualisiert', detail: 'Kundendaten wurden erfolgreich aktualisiert', life: 2000 })
            editCustomerDialog.value = false
            loader.value = false
        }
    })
}

const add_rate = () => {
    rates.value.push({"total": null, "is_valid_from": get_swiss_date(), "is_valid_to": get_swiss_date()})
    rates_count.value = rates.value.length
}

const onRatesEdit = (e) => {
    rates.value[e.index][e.field] = e.newValue
    rates_total.value = 0
    rates.value.forEach(rate => {
        rates_total.value += rate.total
    })
    rates_count.value = rates.value.length
}

const create_rates = () => {
    loader.value = true
    let payload = {
        "customer_id": rates_contact.value,
        "rates": rates.value,
        "position": rates_position.value,
        "bank_account": bank_account.value,
        "vertreter": rep.value
    }
    console.log("payload", payload)
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/create-rates", payload)
        .then(response => {
            if(response.data.message == 'success'){
                toast.add({ severity: 'success', summary: 'Raten wurden erstellt', detail: 'Die Raten wurde erfolgreich erstellt', life: 3000 })
                createInvoiceDialog.value = false
                loader.value = false
            }
        })
}

/*
const download_print_invoices = (id:number) => {
    let cs = id * 1337
    window.open(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/download-print-invoice-pdf?id=" + String(id) + "&cs=" + cs,'_blank')
}
*/

const print_content = ref()
const print_window = () => {
    let printContents = print_content.value.innerHTML
    document.body.innerHTML = printContents
    window.print()
    window.location.reload()
}

const refresh_print_invoice = (id) => {
    let payload = {"contact_id": id}
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/refresh-print-invoice", payload)
        .then(response => {
            toast.add({severity:'success', summary: 'Rechnungen wurde aktualisiert', detail: edit.value.order_name, life: 2000})
            })
}
const refresh_data = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/refresh-all-orders')
        .then(response => {
            get_base_data()
            loader.value = false
            toast.add({severity:'success', summary: 'Aufträge wurden aktualisiert', life: 2000})
        })
}
const view_print_invoice = (name_1, name_2, nr) => {
    let random = Math.trunc(Math.random()*200000) + 1
    let pdf_url = "https://cdn.neuraxis.impaxis.io/c/sprachtechnik/invoices/" + name_1 + "_" + name_2 + "_" + nr + ".pdf?v=" + random
    window.open(pdf_url,'_blank')
}
const get_total_unpaid = (invoices) => {
    let total_unpaid = 0
    for (let key in invoices) {
        total_unpaid += Number(invoices[key].total_remaining_payments)
    }
    return total_unpaid
}
const get_rep = (invoices) => {
    let rep = null
    for (let key in invoices) {
        rep = invoices[key].user_id
        break
    }
    return rep
}

const get_total_unpaid_overdue = (invoices) => {
    let total_unpaid = 0
    for (let key in invoices) {
        if(check_due_date(invoices[key].is_valid_to)) {
            total_unpaid += Number(invoices[key].total_remaining_payments)
        }
    }
    return total_unpaid
}
const get_total_invoices_overdue = (invoices) => {
    let total_invoices_overdue = 0
    for (let key in invoices) {
        if (invoices[key].kb_item_status_id == 8 && check_due_date(invoices[key].is_valid_to)) {
            total_invoices_overdue++
        }
    }
    return total_invoices_overdue
}
const get_total_paid = (invoices) => {
    let total_paid = 0
    for (let key in invoices) {
        total_paid += Number(invoices[key].total_received_payments)
    }
    return total_paid
}
const get_total_rates = (invoices) => {
    let total_rates = 0
    for (let key in invoices) {
        total_rates += Number(invoices[key].total)
    }
    return total_rates
}
const get_total_invoices_paid = (invoices) => {
    let total_invoices_paid = 0
    for (let key in invoices) {
        if (invoices[key].kb_item_status_id == 9) {
            total_invoices_paid++
        }
    }
    return total_invoices_paid
}
const get_bank_account = (id) => {
    let bank_account = ""
    for (let key in bank_accounts.value) {
        if (bank_accounts.value[key].id == id) {
            bank_account = bank_accounts.value[key].bank_name + " (" + bank_accounts.value[key].iban_nr + ")"
            break
        }
    }
    return bank_account
}
const openBexio = (id) => {
    window.open('https://office.bexio.com/index.php/kontakt/show/id/' + String(id) + '#invoices','_blank')
}
const openBexioInvoice = (id) => {
    window.open('https://office.bexio.com/index.php/kb_invoice/show/id/' + String(id), '_blank')
}
const openBexioNotes = (id) => {
    window.open('https://office.bexio.com/index.php/kontakt/show/id/' + String(id) + '#notes', '_blank')
}
const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const format_currency_without_prefix = (value) => {
    return Number(value).toLocaleString('de-CH', {minimumFractionDigits: 2});
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

function swissDate(date) {
    const d = new Date(date)
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    const year = '' + d.getFullYear()
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    return [day, month, year].join('.');
}
function swissDate2iso(date) {
    let month = '' + date.substring(3,5)
    let day = '' + date.substring(0,2)
    let year = '' + date.substring(6,10)
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    return [year, month, day].join('-');
}
const get_swiss_date = () => {
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    return String(day).padStart(2, '0') + "." + String(month).padStart(2, '0') + "." + year
}
const check_due_date = (value) => {
    let today = new Date()
    let due_date = new Date(value)
    if (due_date.getTime() < today.getTime()) {
        return true
    } else {
        return false
    }
}
const difference_in_day = (value) => {
    let today = new Date()
    let due_date = new Date(value)
    let diff = Math.abs(due_date.getTime() - today.getTime())
    let diffDays = Math.ceil(diff / (1000 * 3600 * 24))
    return diffDays
}

const open_url = (url) => {
    window.open(url, '_blank')
}

const has_cash_discount = (payments) => {
    let cash_discount = false
    for (let key in payments) {
        if (payments[key].is_cash_discount == true) {
            cash_discount = true
            break
        }
    }
    return cash_discount
}

const get_cash_discount = (payments) => {
    let cash_discount = 0
    for (let key in payments) {
        if (payments[key].is_cash_discount == true) {
            cash_discount = payments[key].value
            break
        }
    }
    return cash_discount
}

</script>

<style>
.p-inputtext {
    opacity: 1!important;
}
.p-multiselect-token  {
    color: #ffffff!important;
    background-color: #2196F3;
}
.p-datatable-header {
    padding: 0px!important;
}
.non-float-label {
    color: #6c757d;
    font-size: 12px;
    padding-left: 0.5rem;
}
.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}
.p-menubar .p-submenu-list {
    width: 300px;
}
</style>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style> 

<template>
    <Toast />
    <ConfirmDialog style="width: 800px" />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toolbar>
        <template #start>
            <span class="p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText style="width: 400px" v-model="filter_orders['global'].value" placeholder="Suche" />
            </span>
            <Button label="Aktualisieren" @click="refresh_data()" class="w-auto mr-2 p-button-success" icon="pi pi-refresh" />
        </template>
        <template #end>
            <Button label="Aufträge exportieren"  @click="open_url('http://localhost:5000/sprachtechnik/export-orders')" class="w-auto mr-1" type="button" icon="pi pi-file-excel" v-tooltip.left="'Aufträge exportieren'" />
            <Button label="Aufträge exportieren"  @click="open_url('https://api.snpy.ch/sprachtechnik/export-orders')" class="w-auto mr-1" type="button" icon="pi pi-file-excel" v-tooltip.left="'Aufträge exportieren'" />
        </template>
    </Toolbar>
    <DataTable class="mt-4" v-model:filters="filter_orders" :value="orders" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
        <Column field="document_nr" header="Auftrag" sortable>
            <template #body="slotProps">
                <Chip class="pl-0 pr-3 mr-2">
                    <span class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center"><i class="pi pi-file" /></span>
                    <span class="ml-2 font-medium">{{slotProps.data.customer_nr}}</span>
                </Chip>
                <span class="font-bold mr-2 white-space-nowrap">{{slotProps.data.firstname}} {{slotProps.data.lastname}}</span>
                <div class="ml-5">
                    <span class="text-black-alpha-40 text-sm mr-2 white-space-nowrap">{{slotProps.data.product}}</span>
                    <Chip v-if="slotProps.data.language == 1" class="bg-black-alpha-10 text-black-alpha-40 text-xs font-bold" label="DE" />
                    <Chip v-if="slotProps.data.language == 2" class="bg-black-alpha-10 text-black-alpha-40 text-xs font-bold" label="FR" />
                    <Chip v-if="slotProps.data.language == 3" class="bg-black-alpha-10 text-black-alpha-40 text-xs font-bold" label="IT" />
                    <Chip v-if="slotProps.data.language == 4" class="bg-black-alpha-10 text-black-alpha-40 text-xs font-bold" label="EN" />
                </div>
            </template>
        </Column>
        <Column field="order_date" header="Datum" sortable>
            <template #body="slotProps">
                {{format_swiss_date(slotProps.data.order_date)}}
            </template>
        </Column>
        <Column field="lastname" hidden></Column>
        <Column field="firstname" hidden></Column>
        <Column field="customer_nr" hidden></Column>
        <Column field="phone_fixed" hidden></Column>
        <Column field="invoice_references" hidden></Column>
        <Column field="title" header="RG-Betrag / Zahlungen / Restbetrag" sortable>
            <template #body="slotProps">
                <Badge class="mr-2 mb-1" :value="format_currency_without_prefix(slotProps.data.invoices_total)" severity="info" v-tooltip.top="'RG-Betrag'"></Badge>
                <Badge class="mr-2 mb-1" v-if="slotProps.data.paid_total > 0 && !slotProps.data.groups.split(',').includes('13')"  :value="format_currency_without_prefix(slotProps.data.paid_total)" severity="success" v-tooltip.top="'Zahlungen'"></Badge>
                <Badge class="mr-2 mb-1" v-if="slotProps.data.partial_total > 0" :value="format_currency_without_prefix(slotProps.data.partial_total)" severity="warning" v-tooltip.top="'Teilzahlungen'"></Badge>
                <Badge class="mr-2 mb-1" v-if="slotProps.data.unpaid_total > 0" :value="format_currency_without_prefix(slotProps.data.unpaid_total)" severity="danger"  v-tooltip.top="'OP-Restbetrag'"></Badge>
                <Badge class="mr-2 mb-1" v-if="slotProps.data.groups.split(',').includes('13')" severity="danger">Annullation</Badge>
            </template>
        </Column>
        <Column field="id" header="">
            <template #body="slotProps">
                <Button v-if="slotProps.data.unpaid > 0 && !slotProps.data.groups.split(',').includes('13')" class="w-auto p-button-rounded mr-2 bg-primary-50 border-primary-500 mb-1" @click="get_invoices(slotProps.data)">
                    <i class="pi pi-check-circle text-primary-500 mr-2" />
                    <span class="text-black-alpha-90 mr-2">Status:</span>
                    <span class="text-green-500 mr-2">{{ slotProps.data.paid }}</span>
                    <span class="text-black-alpha-90 mr-2">/</span>
                    <span class="text-red-500">{{ slotProps.data.unpaid + slotProps.data.paid }}</span>
                </Button>
                <Button v-if="slotProps.data.unpaid == 0 && !slotProps.data.groups.split(',').includes('13')" class="w-auto p-button-rounded mr-2 bg-green-50 border-green-500 mb-1" @click="get_invoices(slotProps.data)">
                    <i class="pi pi-check-circle text-green-500 mr-2" />
                    <span class="text-black-alpha-90 mr-2">Status:</span>
                    <span class="text-green-500 mr-2">{{ slotProps.data.paid }}</span>
                    <span class="text-black-alpha-90 mr-2">/</span>
                    <span v-if="slotProps.data.partial > 0" class="text-orange-500 mr-2">{{ slotProps.data.partial }}</span>
                    <span v-if="slotProps.data.partial > 0" class="text-black-alpha-90 mr-2">/</span>
                    <span class="text-green-500">{{ slotProps.data.unpaid + slotProps.data.paid }}</span>
                </Button>
                <Button v-if="slotProps.data.groups.split(',').includes('13')" class="w-auto p-button-rounded mr-2 bg-red-50 border-red-500 mb-1" @click="get_invoices(slotProps.data)">
                    <i class="pi pi-times text-red-500 mr-2" />
                    <span class="text-black-alpha-90 mr-2">Status:</span>
                    <span class="text-red-500 mr-2">Annulliert</span>
                </Button>
                <Button v-if="slotProps.data.notes > 0" :label="String(slotProps.data.notes) + 'x Notizen'" icon="pi pi-bell" class="w-auto p-button-rounded bg-orange-50 border-orange-500 text-orange-500 mr-2 mb-1" @click="get_notes(slotProps.data.bexio_customer_id, slotProps.data.firstname + ' ' + slotProps.data.lastname)" />
                <Button v-if="slotProps.data.notes == 0" label="Notizen" icon="pi pi-bell" class="w-auto p-button-rounded bg-primary-50 border-primary-500 text-primary-500 mr-2 mb-1" @click="get_notes(slotProps.data.bexio_customer_id, slotProps.data.firstname + ' ' + slotProps.data.lastname)" />
            </template>
        </Column>
        <Column field="id" header="">
            <template #body="slotProps">
                <Button label="Bexio" @click="openBexio(slotProps.data.bexio_customer_id)" class="w-auto mr-2 mb-1 p-button" style="background-color: #0d2f3b; color: #bbdc00; border: 1px solid #bbdc00" icon="pi pi-eye" />
                <Button label="PDF Drucken" @click="view_print_invoice(slotProps.data.lastname, slotProps.data.firstname, slotProps.data.customer_nr)" class="p-button w-auto mr-2 mb-1" icon="pi pi-print" />
                <Button @click="refresh_print_invoice(slotProps.data.bexio_customer_id)" icon="pi pi-refresh" v-tooltip.top="'Rechnung PDF neu generieren'" class="p-button mb-1" />
            </template>
        </Column>
    </DataTable>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <!--------------------------------------------------->
    <!------------------ Show Notes --------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="showNotes" :style="{width: '1200px'}" :header="note_header" :modal="true" class="p-fluid">
        <DataTable :value="notes" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true">
            <div class="col-12 formgrid grid">
                <div class="field col-6">
                    <Button label="Notiz hinzufügen" @click="create_note(notes_contact_id), notes_data.subject.value = '', notes_data.info.value = ''" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-plus" />
                    <Button label="Im Bexio Anzeigen" @click="openBexioNotes(notes_contact_id)" class="w-auto mr-2 mb-1 p-button-info" style="background-color: #0d2f3b; color: #bbdc00" icon="pi pi-eye" />
                </div>
            </div>
            <Column field="event_start" header="Datum" sortable>
                <template #body="slotProps">
                    <Avatar icon="pi pi-calendar" class="mr-2" style="background-color:#2196F3; color: #ffffff" shape="circle" />
                    {{format_swiss_date(slotProps.data.event_start)}}
                </template>
            </Column>
            <Column field="subject" header="Betreff">
                <template #body="slotProps">
                    {{slotProps.data.subject}}
                </template>
            </Column>
            <Column field="info" header="Details">
                <template #body="slotProps">
                    <span v-html="slotProps.data.info"></span>
                </template>
            </Column>
        </DataTable>
    </Dialog>
    <!--------------------------------------------------->
    <!---------------- Show Invoices -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="showInvoices" :style="{width: '1200px'}" :header="details_heading" :modal="true" class="p-fluid">
        <DataTable :value="invoices" :rows="100" responsiveLayout="scroll" :rowHover="true" :paginator="true" editMode="cell" @cell-edit-complete="onInvoiceRowEdit" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
            <div class="col-12 formgrid grid">
                <div class="field col-12 font-bold">
                    <Menubar :model="status_items" />
                </div>
                <div class="field col-12">
                    <span class="font-bold mr-2">Vertreter:</span><span>{{get_fictional_user(rep)}}</span>
                </div>
                <div class="field col-6">
                    <span class="font-bold mr-2">E-Mail:</span><span><a :href="'mailto:' + contact.mail">{{contact.mail}}</a></span>
                </div>
                <div class="field col-6">
                    <span class="font-bold mr-2">Telefon:</span><span><a :href="'tel:' + contact.phone_fixed">{{contact.phone_fixed}}</a></span>
                </div>
                <div class="field col-6">
                    <span class="font-bold mr-2">Auftragsdatum:</span> <span>{{ String(order_date) }}</span>
                </div>
                <div class="field col-6">
                    <span class="font-bold mr-2">Anmeldedatum:</span> <span>{{ String(registration_date) }}</span>
                </div>
                <div class="field col-4 font-bold">
                    Rechnungen: <Badge :value="String(count_rates)" severity="info"></Badge> = <Badge :value="format_currency(total_invoices)" severity="info"></Badge>
                </div>
                <div class="field col-4 font-bold">
                    Bezahlt: <Badge :value="String(count_rates_paid)" severity="success"></Badge> = <Badge :value="format_currency(total_paid)" severity="success"></Badge>
                </div>
                <div class="field col-4 font-bold" v-if="(total_cash_discounts > 0)">
                    Rabatt: <Badge :value="format_currency(total_cash_discounts)" severity="warning"></Badge>
                </div>
                <div class="field col-4 font-bold" v-if="count_rates_unpaid != 0">
                    Offen: <Badge :value="String(count_rates_unpaid)" severity="danger"></Badge> = <Badge :value="format_currency(total_unpaid)" severity="danger"></Badge>
                </div>
                <div class="field col-4 font-bold" v-if="count_rates_unpaid == 0">
                    Offen: <Badge :value="String(count_rates_unpaid)" class="bg-gray-50 text-black-alpha-90"></Badge>
                </div>
                <div class="field col-4 font-bold" v-if="total_invoices_overdue > 0">
                    Überfällig: <Badge :value="total_invoices_overdue" severity="danger"></Badge> = <Badge :value="format_currency(total_unpaid_overdue)" severity="danger"></Badge>
                </div>
                <!--
                <div class="field col-12 font-bold">
                    <Button size="small" label="Offene Rechnungen löschen" @click="delete_unpaid_invoices(contact.id)" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-trash" />
                    <Button size="small" label="Auftrag & Rechnungen löschen" @click="delete_order_invoices(contact.id)" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-trash" />
                    <Button size="small" label="Raten erstellen" @click="create_rates_dialog(contact.id)" class="w-auto mr-2 mb-1 p-button" icon="pi pi-plus" />
                    <Button size="small" label="Auftrag annulieren" @click="cancel_order_dialog(contact.id)" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                    <Button size="small" label="Inkasso" @click="stall_order_dialog(contact.id)" class="w-auto mr-2 mb-1 p-button-warning" icon="pi pi-ban" />
                    <Button size="small" label="Inkasso rückgängig" @click="unstall_order_dialog(contact.id)" class="w-auto mr-2 mb-1 p-button" icon="pi pi-replay" />
                    <Button size="small" label="Konto Auszug" @click="print_bank_statement()" class="w-auto mr-2 mb-1 p-button" icon="pi pi-briefcase" />
                </div>
                -->
            </div>
            <Column field="document_nr" header="Rechnungs-Nr" sortable>
                <template #body="slotProps">
                    <Avatar icon="pi pi-file" class="mr-2" style="background-color:#2196F3; color: #ffffff" shape="circle" />
                    {{slotProps.data.document_nr}}
                </template>
            </Column>
            <Column field="is_valid_from" header="Datum" sortable>
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" />
                </template>
                <template #body="{ data, field }">
                    {{format_swiss_date(data[field])}}
                </template>
            </Column>
            <Column field="is_valid_to" header="Fällig bis" sortable>
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" />
                </template>
                <template #body="{ data, field }">
                    {{format_swiss_date(data[field])}}
                </template>
            </Column>
            <Column field="total" header="Betrag" sortable>
                <template #body="slotProps">
                    {{format_currency(slotProps.data.total)}}
                </template>
            </Column>
            <Column field="total_remaining_payments" header="Status" sortable>
                <template #body="slotProps">
                    <Badge v-if="Number(slotProps.data.total_remaining_payments) == 0 && slotProps.data.payments[0].bank_account_id == 5" :value="'Annuliert: ' + swissDate(slotProps.data.payments[0].date)" severity="danger" class="mr-2" v-tooltip.top="'Rechnung ist bezahlt'"></Badge>
                    <Badge v-if="Number(slotProps.data.total_remaining_payments) == 0 && slotProps.data.payments[0].bank_account_id == 1 && has_cash_discount(slotProps.data.payments) == false || Number(slotProps.data.total_remaining_payments) == 0 && slotProps.data.payments[0].bank_account_id == 2 && has_cash_discount(slotProps.data.payments) == false" :value="'Bezahlt: ' + swissDate(slotProps.data.payments[0].date)" severity="success" class="mr-2" v-tooltip.top="'Rechnung ist bezahlt'"></Badge>
                    <Badge v-if="Number(slotProps.data.total_remaining_payments) == 0 && slotProps.data.payments[0].bank_account_id == 1 && has_cash_discount(slotProps.data.payments) == true || Number(slotProps.data.total_remaining_payments) == 0 && slotProps.data.payments[0].bank_account_id == 2 && has_cash_discount(slotProps.data.payments) == true" :value="'Bezahlt: ' + format_currency_without_prefix(slotProps.data.payments[0].value) + ' - ' + swissDate(slotProps.data.payments[0].date) + ' - Rabatt: ' + format_currency_without_prefix(get_cash_discount(slotProps.data.payments))" severity="warning" class="mr-2" v-tooltip.top="'Rechnung ist bezahlt'"></Badge>
                    <Badge v-if="Number(slotProps.data.total_remaining_payments) == 0 && slotProps.data.payments[0].bank_account_id == 9" :value="'Rabatt: ' + format_currency_without_prefix(slotProps.data.payments[0].value) + ' - ' + swissDate(slotProps.data.payments[0].date)" severity="warning" class="mr-2" v-tooltip.top="'Rechnung ist bezahlt'"></Badge>
                    <Badge v-if="Number(slotProps.data.total_remaining_payments) == 0 && slotProps.data.payments[0].bank_account_id == 6" :value="'Zuv. bez.: ' + swissDate(slotProps.data.payments[0].date)" severity="warning" class="mr-2" v-tooltip.top="'Zu viel bezahlt'"></Badge>
                    <Badge v-if="Number(slotProps.data.total_remaining_payments) > 0 && !check_due_date(slotProps.data.is_valid_to) && Number(slotProps.data.total_remaining_payments) == Number(slotProps.data.total)" value="Offen" severity="info" class="mr-2" v-tooltip.top="'Rechnung ist ausstehend'"></Badge>
                    <Badge v-if="Number(slotProps.data.total_remaining_payments) != 0 && Number(slotProps.data.total_remaining_payments) != Number(slotProps.data.total)" :value="'Bezahlt: ' + format_currency(slotProps.data.payments[0].value) + ' (' + swissDate(slotProps.data.payments[0].date) + ')'" severity="warning" class="mr-2" v-tooltip.top="'Rechnung wurde Teilweise bezahlt'"></Badge>
                    <Badge v-if="check_due_date(slotProps.data.is_valid_to) && Number(slotProps.data.total_remaining_payments) > 0" :value="'Überfällig (' + difference_in_day(slotProps.data.is_valid_to) + ' Tage)'" severity="danger" v-tooltip.top="'Rechnung ist überfällig'"></Badge>
                    <Badge v-if="Number(slotProps.data.total_remaining_payments) == 0 && slotProps.data.payments[0].bank_account_id == null && has_cash_discount(slotProps.data.payments) == true" :value="'Skonto: ' + format_currency_without_prefix(slotProps.data.payments[0].value) + ' - ' + swissDate(slotProps.data.payments[0].date) + ' - Rabatt: ' + format_currency_without_prefix(get_cash_discount(slotProps.data.payments))" severity="warning" class="mr-2" v-tooltip.top="'Rechnung ist bezahlt'"></Badge>
                </template>
            </Column>
            <Column field="id" header="">
                <template #body="slotProps">
                    <Button @click="openBexioInvoice(slotProps.data.id)" class="p-button-rounded mr-2" style="background-color: #0d2f3b; color: #bbdc00" icon="pi pi-eye" v-tooltip.top="'Rechnung in Bexio öffnen'" />
                </template>
            </Column>
        </DataTable>
    </Dialog>
    <!------------------------------------------------------>
    <!-------------- Create Extra Rates -------------------->
    <!------------------------------------------------------>
    <Dialog v-model:visible="createExtraRateDialog" :style="{width: '1200px'}" header="zuv. bez. KG erstellen" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputNumber id="total"  mode="currency" currency="CHF" locale="de-CH" v-model="rate_total" />
                    <label for="total">Betrag</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <Calendar id="date" dateFormat="dd.mm.yy" v-model="rate_date" :showIcon="true" />
                    <label for="date">Datum</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="title" type="text" v-model="rate_title" />
                    <label for="title">Rechnungs-Titel (Optional)</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <Dropdown id="vertreter" v-model="rep" optionValue="id" optionLabel="lastname" :options="fictional_users"></Dropdown>
                    <label for="vertreter">Vertreter</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button :disabled="rep && rate_total && rate_date ? false : true" label="zuv. bez. KG erstellen" @click="create_extra_rate()" class="w-auto mr-2 p-button-success" icon="pi pi-save" />
                    <Button label="Abbrechen" @click="createExtraRateDialog = false" class="w-auto mr-2 p-button-danger" icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!----------------- Create Rates -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="createInvoiceDialog" :style="{width: '1200px'}" header="Raten erstellen" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <DataTable :value="rates" :rowHover="true" editMode="cell" @cell-edit-complete="onRatesEdit">
                    <Column field="is_valid_from" header="Datum" style="width: 30%">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            {{data[field]}}
                        </template>
                    </Column>
                    <Column field="is_valid_to" header="Fällig bis" style="width: 30%">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            {{data[field]}}
                        </template>
                    </Column>
                    <Column field="total" header="Betrag" style="width: 30%">
                        <template #editor="{ data, field }">
                            <InputNumber v-model="data[field]" mode="currency" currency="CHF" locale="de-CH" />
                        </template>
                        <template #body="{ data, field }">
                            {{format_currency(data[field])}}
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="field col-12 mt-3">
                <Button label="Rate hinzufügen" @click="add_rate()" class="w-auto mr-2 p-button" icon="pi pi-plus" />
                <span class="mr-2 font-bold">{{ rates_count }} x Raten = {{ format_currency(rates_total) }}</span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Dropdown id="bank_account" v-model="bank_account" :options="bank_accounts" optionValue="id" optionLabel="name" />
                    <label for="bank_account">Bankkonto</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="position" type="text" v-model="rates_position" />
                    <label for="position">Position / Beschreibung (Optional)</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <Dropdown id="vertreter" v-model="rep" optionValue="id" optionLabel="lastname" :options="fictional_users"></Dropdown>
                    <label for="vertreter">Vertreter</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button :disabled="rep && bank_account && rates_total != 0 ? false : true" label="Raten erstellen" @click="create_rates()" class="w-auto mr-2 p-button-success" icon="pi pi-save" />
                    <Button label="Abbrechen" @click="createInvoiceDialog = false" class="w-auto mr-2 p-button-danger" icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!------------------ Create Note -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="createNote" :style="{width: '850px'}" header="Notiz erfassen" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <Calendar id="note_date" dateFormat="dd.mm.yy" v-model="notes_data.note_date.value" :showIcon="true" :class="{'p-invalid':notes_data.note_date.error}" />
                    <label for="note_date">{{notes_data.note_date.name}}</label>
                </span>
                <small v-if="(notes_data.note_date.error)" class="p-error">{{notes_data.note_date.message}}</small>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="note_subject" v-model="notes_data.subject.value" :showIcon="true" :class="{'p-invalid':notes_data.subject.error}" />
                    <label for="note_subject">{{notes_data.subject.name}}</label>
                </span>
                <small v-if="(notes_data.subject.error)" class="p-error">{{notes_data.subject.message}}</small>
            </div>
            <div class="field col-12">
                <label for="note_info" class="non-float-label">Details</label>
                <Editor v-model="notes_data.info.value" editorStyle="width: 100%">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                        </span>
                    </template>
                </Editor>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button label="Notiz erfassen" @click="saveNote(notes_contact_id)" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                    <Button label="Abbrechen" @click="createNote = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!----------------- Cancel Order -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="cancelOrderDialog" :style="{width: '1200px'}" header="Auftrag annullieren" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="reason" type="text" v-model="cancel_reason" />
                    <label for="reason">Begründung (Die Notiz wird auf dem Kunden hinterlegt)</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Calendar id="cancel_date" dateFormat="dd.mm.yy" v-model="cancel_date" :showIcon="true" />
                    <label for="cancel_date">Annullierungsdatum</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox1" v-model="contact_groups" value="6" />
                    <label for="checkbox1">Insolvent</label>
                </div>
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox2" v-model="contact_groups" value="7" />
                    <label for="checkbox2">Betreibung</label>
                </div>
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox2" v-model="contact_groups" value="8" />
                    <label for="checkbox2">Fortsetzung</label>
                </div>
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox2" v-model="contact_groups" value="9" />
                    <label for="checkbox2">Pfändung</label>
                </div>
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox2" v-model="contact_groups" value="10" />
                    <label for="checkbox2">Rechtsvorschlag</label>
                </div>
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox2" v-model="contact_groups" value="11" />
                    <label for="checkbox2">Rechtsöffnung</label>
                </div>
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox2" v-model="contact_groups" value="12" />
                    <label for="checkbox2">Verluschtschein</label>
                </div>
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox2" v-model="contact_groups" value="13" />
                    <label for="checkbox2">Annullation</label>
                </div>
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox2" v-model="contact_groups" value="14" />
                    <label for="checkbox2">Sofortkündigung</label>
                </div>
            </div>
            <div class="field col-12 mt-3">
                <InlineMessage severity="error">Achtung! Auftrag und alle Rechnungen werden storniert!</InlineMessage>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button label="Auftrag annullieren" @click="cancel_order()" class="w-auto mr-2 p-button-danger" icon="pi pi-save" />
                    <Button label="Abbrechen" @click="cancelOrderDialog = false" class="w-auto mr-2 p-button" icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>
    <!-------------------------------------------------->
    <!----------------- Stall Order -------------------->
    <!-------------------------------------------------->
    <Dialog v-model:visible="stallOrderDialog" :style="{width: '1200px'}" header="Auftrag auf Inkasso wechseln" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="reason" type="text" v-model="cancel_reason" />
                    <label for="reason">Begründung (Die Notiz wird auf dem Kunden hinterlegt)</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Calendar id="cancel_date" dateFormat="dd.mm.yy" v-model="cancel_date" :showIcon="true" />
                    <label for="cancel_date">Inkasso-Datum</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox1" v-model="contact_groups" value="6" />
                    <label for="checkbox1">Insolvent</label>
                </div>
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox2" v-model="contact_groups" value="7" />
                    <label for="checkbox2">Betreibung</label>
                </div>
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox2" v-model="contact_groups" value="8" />
                    <label for="checkbox2">Fortsetzung</label>
                </div>
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox2" v-model="contact_groups" value="9" />
                    <label for="checkbox2">Pfändung</label>
                </div>
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox2" v-model="contact_groups" value="10" />
                    <label for="checkbox2">Rechtsvorschlag</label>
                </div>
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox2" v-model="contact_groups" value="11" />
                    <label for="checkbox2">Rechtsöffnung</label>
                </div>
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox2" v-model="contact_groups" value="12" />
                    <label for="checkbox2">Verluschtschein</label>
                </div>
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox2" v-model="contact_groups" value="14" />
                    <label for="checkbox2">Sofortkündigung</label>
                </div>
            </div>
            <div class="field col-12 mt-3">
                <InlineMessage severity="error">Achtung! Alle Rechnungen werden auf Entwurf gesetzt!</InlineMessage>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button label="Auftrag auf Inkasso ändern" @click="stall_order()" class="w-auto mr-2 p-button-danger" icon="pi pi-save" />
                    <Button label="Abbrechen" @click="stallOrderDialog = false" class="w-auto mr-2 p-button" icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>
    <!-------------------------------------------------->
    <!---------------- UnStall Order ------------------->
    <!-------------------------------------------------->
    <Dialog v-model:visible="unStallOrderDialog" :style="{width: '1200px'}" header="Auftrag vom Inkasso Status zurückziehen" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="reason" type="text" v-model="cancel_reason" />
                    <label for="reason">Begründung (Die Notiz wird auf dem Kunden hinterlegt)</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox1" v-model="contact_groups" value="6" />
                    <label for="checkbox1">Insolvent</label>
                </div>
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox2" v-model="contact_groups" value="7" />
                    <label for="checkbox2">Betreibung</label>
                </div>
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox2" v-model="contact_groups" value="8" />
                    <label for="checkbox2">Fortsetzung</label>
                </div>
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox2" v-model="contact_groups" value="9" />
                    <label for="checkbox2">Pfändung</label>
                </div>
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox2" v-model="contact_groups" value="10" />
                    <label for="checkbox2">Rechtsvorschlag</label>
                </div>
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox2" v-model="contact_groups" value="11" />
                    <label for="checkbox2">Rechtsöffnung</label>
                </div>
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox2" v-model="contact_groups" value="12" />
                    <label for="checkbox2">Verluschtschein</label>
                </div>
                <div class="field-checkbox">
                    <Checkbox inputId="checkbox2" v-model="contact_groups" value="14" />
                    <label for="checkbox2">Sofortkündigung</label>
                </div>
            </div>
            <div class="field col-12 mt-3">
                <InlineMessage severity="error">Achtung! Alle Rechnungen werden wieder auf Offen gesetzt!</InlineMessage>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button label="Auftrag vom Inkasso Status zurückziehen" @click="unstall_order()" class="w-auto mr-2 p-button-danger" icon="pi pi-save" />
                    <Button label="Abbrechen" @click="unStallOrderDialog = false" class="w-auto mr-2 p-button" icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>
    <!-------------------------------------------------->
    <!--------------- Edit Customer -------------------->
    <!-------------------------------------------------->
    <Dialog v-model:visible="editCustomerDialog" :style="{width: '1000px'}" header="Kundendaten bearbeiten" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12">
                <InlineMessage severity="warn">Wenn du hier Kundendaten bearbeitest, wird auch die Adresse des Kunden in allen unbezahlten Rechnungen angepasst.</InlineMessage>
            </div>
            <div class="field col-4 mt-5">
                <span class="p-float-label">
                    <Dropdown id="salutation" v-model="contact.salutation_id" :options="salutations" optionLabel="name" optionValue="id" />
                    <label for="salutation_id">Anrede</label>
                </span>
            </div>
            <div class="field col-4 mt-5">
                <span class="p-float-label">
                    <Calendar id="birthday" dateFormat="dd.mm.yy" :manualInput="true" :showOnFocus="false" v-model="contact.birthday" :showIcon="true" />
                    <label for="birthday">Geburtsdatum</label>
                </span>
            </div>
            <div class="field col-4 mt-5">
                <span class="p-float-label">
                    <Dropdown id="language_id" v-model="contact.language_id" :options="languages" optionLabel="name" optionValue="id" />
                    <label for="language_id">Sprache</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="name_2" type="text" v-model="contact.name_2" />
                    <label for="name_2">Vornamen</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="name_1" type="text" v-model="contact.name_1" />
                    <label for="name_1">Nachnamen</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="address" type="text" v-model="contact.address" />
                    <label for="address">Adresse}</label>
                </span>
            </div>
            <div class="field col-4 mt-3">
                <span class="p-float-label">
                    <InputText id="postcode" type="text" v-model="contact.postcode" />
                    <label for="postcode">PLZ</label>
                </span>
            </div>
            <div class="field col-8 mt-3">
                <span class="p-float-label">
                    <InputText id="city" type="text" v-model="contact.city" />
                    <label for="city">Ort</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="mail" type="text" v-model="contact.mail" />
                    <label for="mail">E-Mail</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="phone_fixed" type="text" v-model="contact.phone_fixed" />
                    <label for="phone_fixed">Telefon</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button label="Kundendaten speichern" @click="edit_customer()" class="w-auto mr-2 p-button-danger" icon="pi pi-save" />
                    <Button label="Abbrechen" @click="editCustomerDialog = false" class="w-auto mr-2 p-button" icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>

    <!-------------------------------------------------->
    <!--------------- Bank Statement ------------------->
    <!-------------------------------------------------->
    <Dialog v-model:visible="showStatementDialog" :style="{width: '1200px'}" header="Kontoauszug" :modal="true" class="p-fluid">
        <Button label="Kontoauszug Drucken" icon="pi pi-print" @click="print_window()" />
        <div ref="print_content" name="print_content" style="height: 842px; width: 595px;">
            <div class="col-12 formgrid grid">
                <div class="field col-12 mt-3">
                    <img :src="'data:image/png;base64, '+ company_info[0].logo_base64" />
                </div>
                <div class="field col-4 col-offset-8">
                    {{statement_header[0].contact_name}} <br />
                    {{statement_header[0].contact_address}} <br />
                    {{statement_header[0].contact_postcode}} {{statement_header[0].contact_city}}<br />
                    E-Mail: {{statement_header[0].contact_mail}}<br />
                    Nummer: {{statement_header[0].contact_nr}}<br />
                </div>
                <div class="field col-12">
                    <DataTable class="mb-6" :value="statement_header">
                        <Column field="paid" header="Total Bezahlt">
                            <template #body="slotProps">
                                {{slotProps.data.paid}}
                            </template>
                        </Column>
                        <Column field="unpaid" header="Total Offen">
                            <template #body="slotProps">
                                {{slotProps.data.unpaid}}
                            </template>
                        </Column>
                        <Column field="unpaid_overdue" header="Total Überfällig">
                            <template #body="slotProps">
                                {{slotProps.data.unpaid_overdue}}
                            </template>
                        </Column>
                    </DataTable>
                </div>
                <div class="field col-12">
                    <DataTable class="mt-6" :value="invoices" :rows="100" responsiveLayout="scroll">
                        <Column field="is_valid_from" header="Rechnungs-Datum">
                            <template #body="slotProps">
                                {{format_swiss_date(slotProps.data.is_valid_from)}}
                            </template>
                        </Column>
                        <Column field="document_nr" header="Rechnungs-Nr">
                            <template #body="slotProps">
                                {{slotProps.data.document_nr}}
                            </template>
                        </Column>
                        <Column field="is_valid_to" header="Fällig bis">
                            <template #body="slotProps">
                                {{format_swiss_date(slotProps.data.is_valid_to)}}
                            </template>
                        </Column>
                        <Column field="total" header="Betrag">
                            <template #body="slotProps">
                                {{format_currency(slotProps.data.total)}}
                            </template>
                        </Column>
                        <Column field="total_received_payments" header="Bezahlt">
                            <template #body="slotProps">
                                <span v-if="Number(slotProps.data.total_received_payments) > 0">{{format_currency(slotProps.data.total_received_payments)}}</span>
                                <span v-if="Number(slotProps.data.total_credit_vouchers) > 0">-{{format_currency(slotProps.data.total_credit_vouchers)}}</span>
                            </template>
                        </Column>
                        <Column field="total_remaining_payments" header="Offen">
                            <template #body="slotProps">
                                {{format_currency(slotProps.data.total_remaining_payments)}}
                            </template>
                        </Column>
                        <Column header="Zahlungsdatum">
                            <template #body="slotProps">
                                <span v-if="slotProps.data.kb_item_status_id != 8">{{format_swiss_date(slotProps.data.payments[0].date)}}</span>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </Dialog>
</template>