<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'

/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters
const filter_products = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const orders = ref()
const og_orders = ref()
const products = ref()
const metaproducts = ref()
const metaorders = ref()
const editOrderDialog = ref(false)
const order_prov_date = ref()
const order_product = ref()
const order_id = ref()
const fictional_users = ref()
const rep = ref()
const dt = ref()
const filter_rep = ref()
const filter_from = ref()
const filter_to = ref()
const repReportDialog = ref(false)
const report_data = ref()
const report_daily = ref()
const report_monthly = ref()
const ordersReportDialog = ref(false)

const get_base_data = () => {
    // Get Data
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/get-all-bexio-orders')
    .then(response => {
        orders.value = response.data
        og_orders.value = response.data
    })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/get-meta-products')
    .then(response => {
        metaproducts.value = response.data
    })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/get-all-bexio-products')
    .then(response => {
        products.value = response.data
        console.log("products", products.value)
    })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/get-fictional-users')
    .then(response => {
        fictional_users.value = response.data
        for (let key in fictional_users.value){
            fictional_users.value[key]["lastname"] = fictional_users.value[key]["firstname"] + ' ' + fictional_users.value[key]["lastname"]
        }
    })
}

watch(filter_rep, () => {
    if(filter_rep.value != null){
        orders.value = orders.value.filter(order => order.rep == filter_rep.value)
    }
})

watch(filter_from, () => {
    let date = new Date(filter_from.value)
    let swiss_date = date.getDate().toString().padStart(2,'0') + "." + (date.getMonth() + 1).toString().padStart(2,'0') + "." + date.getFullYear()
    if(filter_from.value != null){
        orders.value = orders.value.filter(order => Number(String(order.commission_date).substring(5, 7)) >= Number(String(swiss_date).substring(3,5)) && Number(String(order.commission_date).substring(0, 4)) >= Number(String(swiss_date).substring(6,10)))
    }
})

watch(filter_to, () => {
    let date = new Date(filter_to.value)
    let swiss_date = date.getDate().toString().padStart(2,'0') + "." + (date.getMonth() + 1).toString().padStart(2,'0') + "." + date.getFullYear()
    if(filter_to.value != null){
        orders.value = orders.value.filter(order => Number(String(order.commission_date).substring(5, 7)) <= Number(String(swiss_date).substring(3,5)) && Number(String(order.commission_date).substring(0, 4)) <= Number(String(swiss_date).substring(6,10)))
    }
})

onMounted(() => {
    get_base_data()
})

const exportCSV = () => {
    dt.value.exportCSV()
}

const documentStyle = getComputedStyle(document.documentElement)
const textColor = documentStyle.getPropertyValue('--text-color')
const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary')
const surfaceBorder = documentStyle.getPropertyValue('--surface-border')
const lineChartOptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
        legend: {
            labels: {
                color: textColor
            }
        }
    },
    scales: {
        x: {
            ticks: {
                color: textColorSecondary
            },
            grid: {
                color: surfaceBorder
            }
        },
        y: {
            ticks: {
                color: textColorSecondary
            },
            grid: {
                color: surfaceBorder
            }
        }
    }
}

const orders_report_data = ref()
const orders_report = () => {
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/orders-report', orders.value)
    .then(response => {
        report_data.value = response.data.report_data
        orders_report_data.value = response.data
        console.log("orders", orders_report_data.value)
        ordersReportDialog.value = true
    })

}
const rep_report_data = ref()
const rep_report = () => {
    console.log(orders.value)
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/rep-report', orders.value)
    .then(response => {
        report_data.value = response.data.report_data
        rep_report_data.value = response.data
        repReportDialog.value = true
        report_daily.value = {
            labels: response.data.daily_labels,
            datasets: [
                {
                    label: 'Umsatz von x bis x',
                    data: response.data.daily_values,
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    tension: 0.4
                }
            ]
        }
        report_monthly.value = {
            labels: response.data.monthly_labels_this_year,
            datasets: [
                {
                    label: 'Umsatz 2023',
                    data: response.data.monthly_values_this_year,
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    tension: 0.4
                },
                {
                    label: 'Umsatz 2022',
                    data: response.data.monthly_values_last_year,
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--orange-500'),
                    tension: 0.4
                }
            ]
        }
    })
}

const reset_filters = () => {
    filter_rep.value = null
    filter_from.value = null
    filter_to.value = null
    orders.value = og_orders.value
}

const edit_order = (id, user_id, prov_date, product_code) => {
    order_id.value = id
    order_prov_date.value = format_swiss_date(prov_date)
    order_product.value = product_code
    rep.value = user_id
    console.log("rep:", rep.value)
    editOrderDialog.value = true
}

const save_order = () => {
    let commission_date = new Date(order_prov_date.value)
    console.log("order_prov_date", order_prov_date.value)
    order_prov_date.value = commission_date.getFullYear() + "-" + (commission_date.getMonth() + 1).toString().padStart(2,'0') + "-" + commission_date.getDate().toString().padStart(2,'0')
    console.log("order_prov_date", order_prov_date.value)
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/update-statistics-order', {
        id: order_id.value,
        order_product: order_product.value,
        order_prov_date: order_prov_date.value,
        rep: rep.value
    })
    .then(response => {
        toast.add({severity:'success', summary: 'Kunde aktualisiert', detail: 'Produkt wurde aktualisiert', life: 3000})
        editOrderDialog.value = false
        get_base_data()
    })
}

const get_metaproduct_points = (id) => {
    let points = 0
    for (let key in products.value)
        if(products.value[key]["intern_code"] == id){
            for (let key2 in metaproducts.value)
                if(metaproducts.value[key2]["id"] == products.value[key]["id"]){
                    points = metaproducts.value[key2]["points"]
                    break
                }
            }
    return points
}

const get_metaproduct_prov_a = (id) => {
    let prov_a = 0
    for (let key in products.value)
        if(products.value[key]["intern_code"] == String(id)){
            for (let key2 in metaproducts.value)
                if(metaproducts.value[key2]["id"] == products.value[key]["id"]){
                    prov_a = metaproducts.value[key2]["prov_a"]
                    break
                }
            }
    return prov_a
}

const get_metaproduct_prov_b = (id) => {
    let prov_b = 0
    for (let key in products.value)
        if(products.value[key].intern_code == id){
            for (let key2 in metaproducts.value)
                if(metaproducts.value[key2].id == products.value[key].id){
                    prov_b = metaproducts.value[key2]["prov_b"]
                    break
                }
            }
    return prov_b
}

const get_fictional_user = (id) => {
    let user_name
    for (let key in fictional_users.value)
        if(fictional_users.value[key].id == id){
            user_name = fictional_users.value[key]["lastname"]
        }
    return user_name
}

const debug = () => {
    console.log(order_prov_date.value)
    console.log(order_product.value)
}

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}
const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}
function swissDate2iso(date) {
    let month = '' + date.substring(3,5)
    let day = '' + date.substring(0,2)
    let year = '' + date.substring(6,10)
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    return [year, month, day].join('-');
}
</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toolbar>
        <template #start>
            <span class="p-input-icon-left mr-3" style="width: 300px">
                <i class="pi pi-search" />
                <InputText v-model="filter_products['global'].value" placeholder="Suche" style="width: 300px" />
            </span>
            <Button v-if="filter_rep || filter_from || filter_to" icon="pi pi-refresh" class="p-button mr-3" @click="reset_filters()" />
            <Calendar :disabled="filter_from != null" v-model="filter_from" placeholder="Provisionsdatum von" view="month" dateFormat="mm.yy" :showIcon="true" class="mr-3 w-auto" />
            <Calendar :disabled="filter_to != null" v-model="filter_to" placeholder="Provisionsdatum bis" view="month" dateFormat="mm.yy" :showIcon="true" class="mr-3 w-auto" />
            <Dropdown :disabled="filter_rep != null" v-model="filter_rep" id="vertreter" placeholder="Vertreter auswählen" :options="fictional_users" optionValue="id" optionLabel="lastname" class="mr-3 w-auto" />
        </template>
        <template #end>
        </template>
    </Toolbar>
    <Toolbar>
        <template #start>
            <div class="w-full">
                <Button :disabled="!filter_rep" label="Vertreter&nbsp;Abrechnung" icon="pi pi-file-excel" class="mr-3 w-auto" @click="rep_report()" v-tooltip.right="'Daten exportieren'" />
                <Button :disabled="filter_rep" label="Aufträge Statistik" icon="pi pi-file-excel" class="mr-3 w-auto" @click="orders_report()" v-tooltip.right="'Daten exportieren'" />
            </div>
        </template>
    </Toolbar>
    <DataTable ref="dt" class="mt-4" v-model:filters="filter_products" :value="orders" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
        <Column field="document_nr" header="Auftrag" sortable>
            <template #body="slotProps">
                <Avatar icon="pi pi-file" class="mr-2" style="background-color:#2196F3; color: #ffffff" shape="circle" />
                {{slotProps.data.customer_nr}} - {{slotProps.data.firstname}} {{slotProps.data.lastname}}
            </template>
        </Column>
        <Column field="order_date" header="Datum" sortable>
            <template #body="slotProps">
                {{format_swiss_date(slotProps.data.order_date)}}
            </template>
        </Column>
        <Column field="title" header="Netto" sortable>
            <template #body="slotProps">
                {{format_currency(slotProps.data.total)}}
            </template>
        </Column>
        <Column field="invoices_total" header="RG-Total" sortable>
            <template #body="slotProps">
                {{format_currency(slotProps.data.invoices_total)}}
            </template>
        </Column>
        <Column field="product_code" header="Produkt">
            <template #body="slotProps">
                {{ slotProps.data.product_code }}
            </template>
        </Column>
        <Column field="product_code" header="Punkte">
            <template #body="slotProps">
                {{ get_metaproduct_points(slotProps.data.product_code) }}
            </template>
        </Column>
        <Column header="Provision A">
            <template #body="slotProps">
                {{ format_currency(get_metaproduct_prov_a(slotProps.data.product_code)) }}
            </template>
        </Column>
        <Column header="Provision B">
            <template #body="slotProps">
                {{ format_currency(get_metaproduct_prov_b(slotProps.data.product_code)) }}
            </template>
        </Column>
        <Column field="commission_date" header="Provision Datum">
            <template #body="slotProps">
                {{ format_swiss_date(slotProps.data.commission_date) }}
            </template>
        </Column>
        <Column field="user_id" header="Vertreter">
            <template #body="slotProps">
                {{get_fictional_user(slotProps.data.rep)}}
            </template>
        </Column>
        <Column field="lastname" hidden></Column>
        <Column field="firstname" hidden></Column>
        <Column field="customer_nr" hidden></Column>
        <Column>
            <template #body="slotProps">
                <Button v-tooltip.top="'Auftrag editieren'" class="p-button-rounded p-button-text" icon="pi pi-pencil" @click="edit_order(slotProps.data.bexio_order_id, slotProps.data.user_id, slotProps.data.commission_date, slotProps.data.product_code)" />
            </template>
        </Column>
    </DataTable>
    <!--------------------------------------------------->
    <!------------------- Edit Order -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editOrderDialog" :style="{width: '650px'}" header="Produkt editieren" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <Dropdown placeholder="Produkt auswählen" optionValue="intern_code" v-model="order_product" optionLabel="intern_code"  :options="products" :filter="true" />
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Calendar dateFormat="dd.mm.yy" :showIcon="true" id="prov_date" v-model="order_prov_date"  />
                    <label for="prov_date">Provision Datum</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <Dropdown id="vertreter" placeholder="Vertreter auswählen" v-model="rep" :options="fictional_users" optionValue="id" optionLabel="lastname" />
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button label="Speichern" @click="save_order()" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                    <Button label="Abbrechen" @click="editOrderDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!------------------- Rep Report -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="repReportDialog" :style="{width: '80%'}" header="Vertreterabrechnung" :modal="true" class="p-fluid">
        <TabView>
            <TabPanel>
                <template #header>
                    <i class="pi pi-table mr-2"></i>
                    <span class="mr-3">Detailliert</span>
                </template>
                <div class="col-12 formgrid grid">
                    <div class="field col-12 mt-3">
                        <DataTable class="mt-4" :value="report_data" :rows="20" responsiveLayout="scroll" :rowHover="true">
                            <Column field="registration_date" header="Datum">
                                <template #body="slotProps">
                                    {{format_swiss_date(slotProps.data.registration_date)}}
                                </template>
                            </Column>
                            <Column field="customer_nr" header="Kd-Nummer" />
                            <Column header="Kunden">
                                <template #body="slotProps">
                                    {{slotProps.data.firstname}} {{slotProps.data.lastname}}
                                </template>
                            </Column>
                            <Column field="product_code" header="Kurs" />
                            <Column field="invoices_total" header="Preis">
                                <template #body="slotProps">
                                    {{ format_currency(slotProps.data.invoices_total) }}
                                </template>
                            </Column>
                            <Column field="product_code" header="Punkte">
                                <template #body="slotProps">
                                    {{ get_metaproduct_points(slotProps.data.product_code) }}
                                </template>
                            </Column>
                            <Column field="product_code" header="Provision A">
                                <template #body="slotProps">
                                    {{ format_currency(get_metaproduct_prov_a(slotProps.data.product_code)) }}
                                </template>
                            </Column>
                            <Column field="product_code" header="Provision B">
                                <template #body="slotProps">
                                    {{ format_currency(get_metaproduct_prov_b(slotProps.data.product_code)) }}
                                </template>
                            </Column>
                            <ColumnGroup type="footer">
                                <Row>
                                    <Column footer="Total:" :colspan="4" footerStyle="text-align:left" />
                                    <Column :footer="format_currency(rep_report_data.total_price)" />
                                    <Column :footer="rep_report_data.total_points" />
                                    <Column :footer="format_currency(rep_report_data.total_prov_a)" />
                                    <Column :footer="format_currency(rep_report_data.total_prov_b)" />
                                </Row>
                            </ColumnGroup>
                        </DataTable>
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="pi pi-chart-line mr-2"></i>
                    <span class="mr-3">Umsatz</span>
                </template>
                <Chart type="line" :data="report_daily" :options="lineChartOptions" class="h-30rem" />
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="pi pi-chart-line mr-2"></i>
                    <span class="mr-3">Jahresstatistik</span>
                </template>
                <Chart type="line" :data="report_monthly" :options="lineChartOptions" class="h-30rem" />
            </TabPanel>
        </TabView>
    </Dialog>
    <!--------------------------------------------------->
    <!----------------- Orders Report ------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="ordersReportDialog" :style="{width: '80%'}" header="Aufträge Statistik" :modal="true" class="p-fluid">
        <TabView>
            <TabPanel>
                <template #header>
                    <i class="pi pi-table mr-2"></i>
                    <span class="mr-3">Detailliert</span>
                </template>
                <div class="col-12 formgrid grid">
                    <div class="field col-12 mt-3">
                        <DataTable class="mt-4" :value="report_data" :rows="20" responsiveLayout="scroll" :rowHover="true">
                            <Column field="registration_date" header="Datum">
                                <template #body="slotProps">
                                    {{format_swiss_date(slotProps.data.registration_date)}}
                                </template>
                            </Column>
                            <Column field="customer_nr" header="Kd-Nummer" />
                            <Column header="Kunden">
                                <template #body="slotProps">
                                    {{slotProps.data.firstname}} {{slotProps.data.lastname}}
                                </template>
                            </Column>
                            <Column field="product_code" header="Kurs" />
                            <Column field="invoices_total" header="Preis">
                                <template #body="slotProps">
                                    {{ format_currency(slotProps.data.invoices_total) }}
                                </template>
                            </Column>
                            <Column field="product_code" header="Punkte">
                                <template #body="slotProps">
                                    {{ get_metaproduct_points(slotProps.data.product_code) }}
                                </template>
                            </Column>
                            <Column field="product_code" header="Provision A">
                                <template #body="slotProps">
                                    {{ format_currency(get_metaproduct_prov_a(slotProps.data.product_code)) }}
                                </template>
                            </Column>
                            <Column field="product_code" header="Provision B">
                                <template #body="slotProps">
                                    {{ format_currency(get_metaproduct_prov_b(slotProps.data.product_code)) }}
                                </template>
                            </Column>
                            <ColumnGroup type="footer">
                                <Row>
                                    <Column footer="Total:" :colspan="4" footerStyle="text-align:left" />
                                    <Column :footer="format_currency(orders_report_data.total_price)" />
                                    <Column :footer="orders_report_data.total_points" />
                                    <Column :footer="format_currency(orders_report_data.total_prov_a)" />
                                    <Column :footer="format_currency(orders_report_data.total_prov_b)" />
                                </Row>
                            </ColumnGroup>
                        </DataTable>
                    </div>
                </div>
            </TabPanel>
        </TabView>
    </Dialog>
</template>