<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'

/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

const filter_courses = ref({global: {value: '', matchMode: FilterMatchMode.CONTAINS}})

const courses = ref([])
const course = ref(null)
const booklets = ref([])
const lessons = ref([])
const exercises = ref([])
const chapters = ref([])

const course_index = ref(null)
const booklets_index = ref(null)
const lessons_index = ref(null)
const exercises_index = ref(null)
const chapters_index = ref(null)

const changes_courses = ref(0)
const changes_course = ref(0)
const changes_booklets = ref(0)
const changes_lessons = ref(0)
const changes_exercises = ref(0)
const changes_chapters = ref(0)

const expanded_booklets = ref([])
const expanded_lessons = ref([])
const expanded_exercises = ref([])
const expanded_chapters = ref([])

const course_id = ref(null)
const exercise_id = ref(null)
const booklet_id = ref(null)
const lesson_id = ref(null)
const chapter_id = ref(null)
const exercise = ref(null)
const roles = ref([])
const translations = ref([{'word': "Hello"}, {'word': "World"}])
const course_language = ref(null)
const student_language = ref(null)
const exercise_data = ref(null)
const exercise_type = ref(null)
const exercise_input = ref(null)

const editing = ref(0)
const playback_rate = ref(0.8)

const edit_course_dialog = ref(false)
const config_booklets_dialog = ref(false)
const config_lessons_dialog = ref(false)
const config_chapters_dialog = ref(false)
const config_exercises_dialog = ref(false)
const edit_reading_exercise_dialog = ref(false)
const edit_info_exercise_dialog = ref(false)
const edit_vocabulary_exercise_dialog = ref(false)
const edit_pronunciation_exercise_dialog = ref(false)

const playback_rates = ref([
    {name: '0.5x', value: 0.5},
    {name: '0.8x', value: 0.8},
    {name: '1.0x', value: 1},
    {name: '1.2x', value: 1.2},
    {name: '1.5x', value: 1.5},
    {name: '2.0x', value: 2.0}
])

const languages = ref([
    {name: 'German', code: 'DE'},
    {name: 'English', code: 'EN'},
    {name: 'French', code: 'FR'},
    {name: 'Italian', code: 'IT'},
    {name: 'Spanish', code: 'ES'},
    {name: 'Portuguese', code: 'PT'},
    {name: 'Dutch', code: 'NL'},
    {name: 'Russian', code: 'RU'},
    {name: 'Chinese', code: 'ZH'},
    {name: 'Japanese', code: 'JA'}
])

const statuses = ref([
    {name: 'Draft', code: 'draft'},
    {name: 'Published', code: 'published'},
    {name: 'Archived', code: 'archived'}
])

const exercise_types = ref([
    {name: 'Reading', code: 'reading'},
    {name: 'Vocabulary', code: 'vocabulary'},
    {name: 'Instructions', code: 'instructions'},
    {name: 'Pronunciation', code: 'pronunciation'},
    {name: 'Info', code: 'info'},
    {name: 'Quiz: Multiple Choice', code: 'quiz_mc'},
    {name: 'Quiz: Fill in the blanks', code: 'quiz_fib'},
    {name: 'Quiz: Image Choice', code: 'quiz_image_choice'},
    {name: 'Quiz: Word Sequence', code: 'quiz_word_sequence'},
    {name: 'Chatbot: Roleplay Q&A', code: 'chatbot_roleplay_qa'},
    {name: 'Recording: Reading', code: 'recording_reading'},
    {name: 'Recording: Listening', code: 'recording_listening'}
])

const voices = ref([])

const providers = ref([
    {name: 'Elevenlabs', code: 'Elevenlabs'},
    {name: 'Google', code: 'Google'},
    {name: 'Amazon', code: 'Amazon'}
])

const new_course = ref({
    name: '',
    student_language: 'DE',
    course_language: 'DE',
    status: 'draft',
    roles: [
        {
            name: 'Narrator',
            language: 'DE',
            voice: 'Xb7hH8MSUJpSbSDYk0k2',
            provider: 'Elevenlabs'
        }
    ],
    booklets: []
})

onMounted(() => {
    playback_rate.value = Number(localStorage.getItem('playbackrate'))
    selected_course.value = localStorage.getItem('selected_course')
    console.log("playback_rate", playback_rate.value)
    get_courses()
    get_voices()
})

const get_voices = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/coursemaker/get-voices')
    .then(response => {
        voices.value = response.data
    })
}


const get_translations = (word, course_language, student_language, index = null) => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/coursemaker/get-translations', {"word": word, "course_language": course_language, "student_language": student_language, "index": index})
    .then(response => {
        console.log("translations", response.data)
        translations.value = response.data
        loader.value = false
    })
}

const save_playback_rate = () => {
    localStorage.setItem('playbackrate', playback_rate.value)
}

///////////////
//// Roles ////
///////////////

const add_role = () => {
    course.value.roles.push({
        name: '',
        voice: '',
        provider: 'Elevenlabs'
    })
    changes_courses.value++
    changes_course.value++
}

const edit_row_roles = (e) => {
    if (course.value.roles[e.index][e.field] != e.newValue) {
        course.value.roles[e.index][e.field] = e.newValue
        changes_courses.value++
        changes_course.value++
    }
    editing.value--
}

const save_course = () => {
    courses.value[course_index.value] = course.value
    changes_course.value = 0
    changes_courses.value++
}

const delete_role = (slotProps) => {
    course.value.roles.splice(slotProps.index, 1)
    changes_course.value++
    changes_courses.value++
}

/////////////////
//// Courses ////
/////////////////

const course_selector = ref([])
const create_course_selector = (courses_res) => {
    course_selector.value = courses_res.map(course => ({label: course.name, value: course.id}))
}
const selected_course = ref(null)
const course_selected = () => {
    localStorage.setItem('selected_course', selected_course.value)
    get_courses()
}

const get_courses = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/coursemaker/get-courses')
    .then(response => {
        courses.value = response.data
        create_course_selector(response.data)
        //filter courses by selected course
        if (selected_course.value != null) {
            courses.value = courses.value.filter(course => course.id == selected_course.value)
        } else {
            courses.value = []
        }
        changes_courses.value = 0
    })
}

const save_courses = () => {
    let payload = {
        "courses": courses.value,
        "selected_course": selected_course.value
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/coursemaker/save-courses', payload)
    .then(response => {
        toast.add({severity:'success', summary: 'Courses saved', detail: 'Courses have been saved', life: 3000})
        changes_courses.value = 0
        get_courses()
    })
}

const new_course_dialog = ref(false)
const add_course = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/coursemaker/create-id')
    .then(response => {
        new_course.value.id = response.data.id1
        new_course_dialog.value = true
        changes_courses.value++
    })
}

const save_new_course = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/coursemaker/save-new-course', new_course.value)
    .then(response => {
        new_course_dialog.value = false
        get_courses()
        create_course_selector(courses.value)
        toast.add({severity:'success', summary: 'Course added', detail: 'Course has been added', life: 3000})
        loader.value = false
    })
}

const edit_row_courses = (e) => {
    courses.value[e.index] = e.newData
    changes_courses.value++
}

const reorder_row_courses = (e) => {
    courses.value = e.value
    changes_courses.value++
}

const edit_course = (e) => {
    course.value = e.data
    course_index.value = e.index
    edit_course_dialog.value = true
    editing.value = 0
}

const delete_course = (index) => {
    confirm.require({
        message: 'Are you sure you want to delete this course?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Yes, delete course',
        rejectLabel: 'No',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/coursemaker/delete-course', {id: courses.value[index].id})
            .then(response => {
                toast.add({severity:'success', summary: 'Course deleted', detail: 'Course has been deleted', life: 3000})
                get_courses()
                create_course_selector(courses.value)
                loader.value = false
            })
        }
    })
}

//////////////////
//// Booklets ////
//////////////////

const config_booklets = (e) => {
    booklets.value = e.data.booklets
    course_id.value = e.data.id
    config_booklets_dialog.value = true
    editing.value = 0
}

const edit_booklet = (e) => {
    if (booklets.value[e.index][e.field] != e.newValue) {
        booklets.value[e.index][e.field] = e.newValue
        changes_booklets.value++
    }
    editing.value--
}

const reorder_booklets = (e) => {
    booklets.value = e.value
    changes_booklets.value++
}

const add_booklet = (slotProps) => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/coursemaker/create-id')
    .then(response => {
        booklets.value.push({"id": response.data.id1, "name": 'booklet ' + String(booklets.value.length + 1), "lessons": [], "course_id": course_id.value})
        changes_booklets.value++
    })
}

const save_booklets = () => {
    booklets_index.value = courses.value.findIndex(course => course.id == course_id.value)
    courses.value[booklets_index.value].booklets = booklets.value
    changes_booklets.value = 0
    changes_courses.value++
    editing.value = 0
    config_booklets_dialog.value = false
}

const delete_booklet = (index) => {
    confirm.require({
        message: 'Are you sure you want to delete this booklet?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Yes, delete booklet',
        rejectLabel: 'No',
        accept: () => {
            booklets.value.splice(index, 1)
            changes_booklets.value++
        }
    })
}

/////////////////
//// Lessons ////
/////////////////

const config_lessons = (e) => {
    lessons.value = e.data.lessons
    booklet_id.value = e.data.id
    course_id.value = e.data.course_id
    config_lessons_dialog.value = true
    editing.value = 0
}

const edit_lessons = (e) => {
    if (lessons.value[e.index][e.field] != e.newValue) {
        lessons.value[e.index][e.field] = e.newValue
        changes_lessons.value++
    }
    editing.value--
}

const reorder_lessons = (e) => {
    lessons.value = e.value
    changes_lessons.value++
}

const add_lesson = (slotProps) => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/coursemaker/create-id')
    .then(response => {
        lessons.value.push({"id": response.data.id1, "name": 'lesson ' + String(lessons.value.length + 1), "chapters": [], "booklet_id": booklet_id.value, "course_id": course_id.value})
        changes_lessons.value++
    })
}

const save_lessons = () => {
    booklets_index.value = courses.value.findIndex(course => course.id == course_id.value)
    lessons_index.value = courses.value[booklets_index.value].booklets.findIndex(booklet => booklet.id == booklet_id.value)
    courses.value[booklets_index.value].booklets[lessons_index.value].lessons = lessons.value
    changes_lessons.value = 0
    changes_courses.value++
    config_lessons_dialog.value = false
    editing.value = 0
}                                                                                               

const delete_lesson = (index) => {
    confirm.require({
        message: 'Are you sure you want to delete this lesson?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Yes, delete lesson',
        rejectLabel: 'No',
        accept: () => {
            lessons.value.splice(index, 1)
            changes_lessons.value++
        }
    })
}

//////////////////
//// Chapters ////
//////////////////

const config_chapters = (e) => {
    chapters.value = e.data.chapters
    lesson_id.value = e.data.id
    booklet_id.value = e.data.booklet_id
    course_id.value = e.data.course_id
    config_chapters_dialog.value = true
    editing.value = 0
}

const edit_chapters = (e) => {
    if (chapters.value[e.index][e.field] != e.newValue) {
        chapters.value[e.index][e.field] = e.newValue
        changes_chapters.value++
    }
    editing.value--
}

const reorder_chapters = (e) => {
    chapters.value = e.value
    changes_chapters.value++
}

const add_chapter = (slotProps) => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/coursemaker/create-id')
    .then(response => {
        chapters.value.push({"id": response.data.id1, name: 'chapter ' + String(chapters.value.length + 1), "exercises": [], "lesson_id": lesson_id.value, "booklet_id": booklet_id.value, "course_id": course_id.value})
        changes_chapters.value++
    })
}

const save_chapters = () => {
    booklets_index.value = courses.value.findIndex(course => course.id == course_id.value)
    lessons_index.value = courses.value[booklets_index.value].booklets.findIndex(booklet => booklet.id == booklet_id.value)
    chapters_index.value = courses.value[booklets_index.value].booklets[lessons_index.value].lessons.findIndex(lesson => lesson.id == lesson_id.value)
    courses.value[booklets_index.value].booklets[lessons_index.value].lessons[chapters_index.value].chapters = chapters.value
    changes_chapters.value = 0
    changes_courses.value++
    config_chapters_dialog.value = false
    editing.value = 0
}

const delete_chapter = (index) => {
    confirm.require({
        message: 'Are you sure you want to delete this chapter?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Yes, delete chapter',
        rejectLabel: 'No',
        accept: () => {
            chapters.value.splice(index, 1)
            changes_chapters.value++
        }
    })
}

///////////////////
//// Exercises ////
///////////////////

const config_exercises = (e) => {
    exercises.value = e.data.exercises
    chapter_id.value = e.data.id
    lesson_id.value = e.data.lesson_id
    booklet_id.value = e.data.booklet_id
    course_id.value = e.data.course_id
    config_exercises_dialog.value = true
    editing.value = 0
}

const edit_exercises = (e) => {
    if (exercises.value[e.index] != e.newData) {
        exercises.value[e.index] = e.newData
        changes_exercises.value++
    }
    editing.value--
}

const reorder_exercises = (e) => {
    exercises.value = e.value
    changes_exercises.value++
}

const add_exercise = (slotProps) => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/coursemaker/create-id')
    .then(response => {
        exercises.value.push({"id": response.data.id1, "name": 'exercise ' + String(exercises.value.length + 1), "input": '', "type": 'reading', "lesson_id": lesson_id.value, "booklet_id": booklet_id.value, "course_id": course_id.value, "chapter_id": chapter_id.value})
        changes_exercises.value++
    })
}

const save_exercises = () => {
    booklets_index.value = courses.value.findIndex(course => course.id == course_id.value)
    lessons_index.value = courses.value[booklets_index.value].booklets.findIndex(booklet => booklet.id == booklet_id.value)
    chapters_index.value = courses.value[booklets_index.value].booklets[lessons_index.value].lessons.findIndex(lesson => lesson.id == lesson_id.value)
    exercises_index.value = courses.value[booklets_index.value].booklets[lessons_index.value].lessons[chapters_index.value].chapters.findIndex(chapter => chapter.id == chapter_id.value)
    courses.value[booklets_index.value].booklets[lessons_index.value].lessons[chapters_index.value].chapters[exercises_index.value].exercises = exercises.value
    changes_exercises.value = 0
    changes_courses.value++
    config_exercises_dialog.value = false
    editing.value = 0
}

const delete_exercise = (index) => {
    confirm.require({
        message: 'Are you sure you want to delete this exercise?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Yes, delete exercise',
        rejectLabel: 'No',
        accept: () => {
            exercises.value.splice(index, 1)
            changes_exercises.value++
        }
    })
}

const edit_exercise = (e) => {
    loader.value = true
    exercise.value = e.data
    exercise_input.value = e.data.input
    exercise_id.value = e.data.id
    course_id.value = e.data.course_id
    booklet_id.value = e.data.booklet_id
    lesson_id.value = e.data.lesson_id
    exercise_type.value = e.data.type
    courses.value.forEach((course) => {
        if(course.id == e.data.course_id){
            roles.value = course.roles
            course_language.value = course.course_language
            student_language.value = course.student_language
        }
    })
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/coursemaker/get-exercise', {id: e.data.id})
    .then(response => {
        exercise_data.value = response.data
        exercise_data.value["input"] = exercise_input.value
        exercise_data.value["exercise_id"] = exercise_id.value
        exercise_data.value["course_id"] = course_id.value
        exercise_data.value["booklet_id"] = booklet_id.value
        exercise_data.value["lesson_id"] = lesson_id.value
        exercise_data.value["chapter_id"] = chapter_id.value
        exercise_data.value["roles"] = roles.value
        exercise_data.value["course_language"] = course_language.value
        exercise_data.value["student_language"] = student_language.value
        exercise_data.value["exercise_type"] = exercise_type.value
        loader.value = false
        if (exercise_type.value == 'reading') {
            edit_reading_exercise_dialog.value = true
            editing.value = 0
        }
        if (exercise_type.value == 'info') {
            edit_info_exercise_dialog.value = true
            editing.value = 0
        }
        if (exercise_type.value == 'vocabulary') {
            if (exercise_data.value.vocabs == undefined) {
                exercise_data.value.vocabs = []
            }
            edit_vocabulary_exercise_dialog.value = true
            editing.value = 0
        }
        if (exercise_type.value == 'pronunciation') {
            edit_pronunciation_exercise_dialog.value = true
            if (exercise_data.value.vocabs == undefined) {
                exercise_data.value.vocabs = []
            }
            editing.value = 0
        }
        console.log("fetched exercise_data", exercise_data.value)
    })
}

const process_exercise = (type, step) => {
    loader.value = true
    let payload = {
        "exercise_input": exercise_data.value.input,
        "exercise_type": type,
        "exercise_step": step,
        "exercise_data": exercise_data.value,
        "course_language": course_language.value,
        "student_language": student_language.value
    }
    console.log("payload", payload)
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/coursemaker/process-exercise', payload)
    .then(response => {
        exercise_data.value = response.data
        console.log("exercise_data", exercise_data.value)
        loader.value = false
    })
}

const save_exercise = () => {
    let payload = {
        "exercise_id": exercise_id.value,
        "exercise_data": exercise_data.value
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/coursemaker/save-exercise', payload)
    .then(response => {
        toast.add({severity:'success', summary: 'Exercise saved', detail: 'Exercise has been saved', life: 3000})
        editing.value = 0
    })
}

const play_audio = (audio_url) => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/coursemaker/play-audio', {"audio_url": audio_url})
    .then(response => {
        let audio = response.data.audio;
        const audioplayer = new Audio(`data:audio/mp3;base64,${audio}`)
        //slow down speed
        audioplayer.playbackRate = playback_rate.value
        audioplayer.play()
        loader.value = false
    })
}

const play_preview = (data) => {
    loader.value = true
    console.log("play audio", data)
    let payload = {
        "language": get_language_name(data.language),
        "voice_id": data.voice,
        "voice": get_voice_name(data.voice)
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/coursemaker/play-demo-audio', payload)
    .then(response => {
        let audio = response.data
        const audioplayer = new Audio(`data:audio/mp3;base64,${audio}`)
        //slow down speed
        audioplayer.play()
        loader.value = false
    })
}

const eval_reading_sentences = (sentences) => {
    let valid = true
    sentences.forEach((sentence, index) => {
        if (sentence.sentence == '' || sentence.role == '') {
            valid = false
            return false
        }
    })
    return valid
}

const eval_reading_mappings = (sentences) => {
    let valid = true
    sentences.forEach((sentence, index) => {
        sentence.mapping.forEach((mapping, index) => {
            if (mapping.word == '' || mapping.translation == '') {
                valid = false
                return false
            }
        })
    })
    return valid
}

const eval_vocab_table = (vocabs) => {
    let valid = true
    vocabs.forEach((vocab, index) => {
        if (vocab.sentence == '' || vocab.consonant == '' || vocab.sentence_translation == '') {
            valid = false
            return false
        }
    })
    return valid
}

const eval_vocab_table_2_columns = (vocabs) => {
    let valid = true
    vocabs.forEach((vocab, index) => {
        if (vocab.sentence == '' || vocab.sentence_translation == '') {
            valid = false
            return false
        }
    })
    return valid
}

//////////////////////////
//// Reading Exercise ////
//////////////////////////

const add_sentence = () => {
    exercise_data.value.sentences.push({sentence: ''})
}

const delete_sentence = (index) => {
    exercise_data.value.sentences.splice(index, 1)
}

const edit_sentence = (e) => {
    if (exercise_data.value.sentences[e.index] != e.newData) {
        exercise_data.value.sentences[e.index] = e.newData
    }
    editing.value--
}

const reorder_sentences = (e) => {
    exercise_data.value.sentences = e.value
}

const add_map_sentence = (index, data) => {
    exercise_data.value["sentences"][index]["mapping"].push({word: '', translation: ''})
}

const edit_map_sentence = (index, e) => {
    if (exercise_data.value["sentences"][index]["mapping"][e.index][e.field] != e.newValue) {
        exercise_data.value["sentences"][index]["mapping"][e.index][e.field] = e.newValue
    }
    editing.value--
}

const reorder_map_sentences = (index, e) => {
    exercise_data.value["sentences"][index]["mapping"] = e.value
}

const delete_map_sentence = (index, e) => {
    exercise_data.value["sentences"][index]["mapping"].splice(e.index, 1)
}

////////////////////////
//// Vocab Exercise ////
////////////////////////

const add_vocab = () => {
    exercise_data.value.vocabs.push({consonant: '', sentence: '', sentence_translation: ''})
}

const delete_vocab = (index) => {
    exercise_data.value.vocabs.splice(index, 1)
}

const edit_vocab = (e) => {
    if (exercise_data.value.vocabs[e.index] != e.newData) {
        exercise_data.value.vocabs[e.index] = e.newData
    }
    editing.value--
}

const reorder_vocabs = (e) => {
    exercise_data.value.vocabs = e.value
}

const vocab_file_upload = (e) => {
    let reader = new FileReader()
    let file = null
    reader.readAsDataURL(e.files[0])
    reader.onload = () => {
        file = reader.result
        let payload = {
            "file": file,
            "filename": e.files[0].name,
            "course_id": course_id.value,
            "exercise_id": exercise_id.value,
            "type": "image"
        }
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/coursemaker/upload-file", payload)
            .then(response => {
                exercise_data.value.image_url = response.data.url
            }).catch(error => {
            console.error("There was an error!", error.message);
        })
    }
}

////////////////////////
//// Helper methods ////
////////////////////////

const get_language_name = (code) => {
    let language_name = ''
    for (let key in languages.value)
        if(languages.value[key].code == code){
            language_name = languages.value[key]["name"]
        }
    return language_name
}

const get_status_name = (code) => {
    let status_name = ''
    for (let key in statuses.value)
        if(statuses.value[key].code == code){
            status_name = statuses.value[key]["name"]
        }
    return status_name
}

const get_exercise_type_name = (code) => {
    let exercise_type_name = ''
    for (let key in exercise_types.value)
        if(exercise_types.value[key].code == code){
            exercise_type_name = exercise_types.value[key]["name"]
        }
    return exercise_type_name
}

const get_voice_name = (id) => {
    let voice_name = ''
    for (let key in voices.value)
        if(voices.value[key].id == id){
            voice_name = voices.value[key]["name"]
        }
    return voice_name
}

const init_cell = (e) => {
    console.log("init cell", e)
    editing.value++
    if (e.field == 'translation') {
        get_translations(e.data.word, get_language_name(course_language.value), get_language_name(student_language.value), e.index)
    }
}

const open_url = (url) => {
    window.open(url, '_blank')
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <Toast />
    <ConfirmDialog />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toolbar>
        <template #start>
            <span class="p-input-icon-left mr-3">
                <i class="pi pi-search" />
                <InputText v-model="filter_courses['global'].value" placeholder="Suche" style="width: 400px" />
            </span>
            <Button label="Save changes" icon="pi pi-save" class="p-button-success mr-2" :disabled="changes_courses == 0 ? true : false" @click="save_courses()" :badge="String(changes_courses)" badgeSeverity="danger" />
            <Button v-if="changes_courses > 0" icon="pi pi-refresh" class="p-button-primary mr-2" @click="get_courses()" v-tooltip.top="'Refresh data'" />
            <Dropdown :options="course_selector" optionLabel="label" optionValue="value" v-model="selected_course" placeholder="Select course" style="width: 400px" @change="course_selected()" />
        </template>
    </Toolbar>
    <div rounded class="overflow-hidden shadow-4 rounded border-200 mt-4">
        <DataTable v-model:filters="filter_courses" v-model:expandedRows="expanded_booklets" :value="courses" responsiveLayout="scroll" editMode="cell" @cell-edit-complete="edit_row_courses" :rowHover="true">
            <Column expander style="width: 4rem" />
            <Column field="name" header="Course name">
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" />
                </template>
                <template #body="{ data, field }">
                    <Avatar icon="pi pi-book" class="mr-2 bg-primary text-white" shape="circle" />
                    <span class="font-bold mr-3">{{data[field]}}</span>
                    <Badge v-if="data.booklets.length > 0" :value="String(data.booklets.length)" />
                </template>
            </Column>
            <Column field="student_language" header="Student language">
                <template #editor="{ data, field }">
                    <Dropdown v-model="data[field]" :options="languages" optionLabel="name" optionValue="code" />
                </template>
                <template #body="{ data, field }">
                    <span>{{get_language_name(data[field])}}</span>
                </template>
            </Column>
            <Column field="course_language" header="Course language">
                <template #editor="{ data, field }">
                    <Dropdown v-model="data[field]" :options="languages" optionLabel="name" optionValue="code" />
                </template>
                <template #body="{ data, field }">
                    <span>{{get_language_name(data[field])}}</span>
                </template>
            </Column>
            <Column field="status" header="Status">
                <template #editor="{ data, field }">
                    <Dropdown v-model="data[field]" :options="statuses" optionLabel="name" optionValue="code" />
                </template>
                <template #body="{ data, field }">
                    <Chip v-if="data[field] == 'published'" :label="get_status_name(data[field])" icon="pi pi-check" />
                    <Chip v-if="data[field] == 'draft'" :label="get_status_name(data[field])" icon="pi pi-times" />
                </template>
            </Column>
            <Column style="width: 300px">
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="mr-2 p-button-rounded p-button-text p-button-primary" @click="edit_course(slotProps)" v-tooltip.top="'Edit course'" />
                    <Button icon="pi pi-cog" class="mr-2 p-button-rounded p-button-text p-button-primary text-cyan-600" @click="config_booklets(slotProps)" v-tooltip.top="'Configure booklets'" />
                    <Button icon="pi pi-trash" class="mr-2 p-button-rounded p-button-text p-button-danger" @click="delete_course(slotProps.index)" v-tooltip.top="'Delete course'" />
                    <Button icon="pi pi-copy" class="mr-2 p-button-rounded p-button-text p-button-primary" @click="copy_course(slotProps.data)" v-tooltip.top="'Copy course'" />
                </template>
                <template #header="slotProps">
                    <Button icon="pi pi-plus" class="mr-2 p-button-rounded" @click="add_course(slotProps)" v-tooltip.top="'Create new course'" />
                </template>
            </Column>
            <template #expansion="slotProps">
                <div rounded class="overflow-hidden shadow-4 rounded border-200">
                    <DataTable v-model:expandedRows="expanded_lessons" :value="slotProps.data.booklets" responsiveLayout="scroll" :rowHover="true">
                        <Column expander style="width: 4rem" />
                        <Column field="name" header="Booklet name">
                            <template #editor="{ data, field }">
                                <InputText v-model="data[field]" />
                            </template>
                            <template #body="{ data, field }">
                                <Avatar icon="pi pi-folder" class="mr-2 bg-cyan-600 text-white" shape="circle" />
                                <span class="font-bold mr-3">{{data[field]}}</span>
                                <Badge v-if="data.lessons.length > 0" :value="String(data.lessons.length)" />
                            </template>
                        </Column>
                        <Column style="width: 200px">
                            <template #body="slotProps">
                                <Button icon="pi pi-cog" class="mr-2 p-button-rounded p-button-text p-button-primary text-orange-600" @click="config_lessons(slotProps)" v-tooltip.top="'Configure lessons'" />
                                <Button icon="pi pi-eye" class="mr-2 p-button-rounded p-button-text p-button-primary text-orange-600" @click="open_url('https://sprachtechnik-app-app-100-lwnjocwfda-oa.a.run.app/'+slotProps.data.course_id+'/'+slotProps.data.id)" v-tooltip.top="'Open booklet'" />
                            </template>
                        </Column>
                        <template #expansion="slotProps">
                            <div rounded class="overflow-hidden shadow-4 rounded border-200">
                                <DataTable v-model:expandedRows="expanded_chapters" :value="slotProps.data.lessons" responsiveLayout="scroll" :rowHover="true" >
                                    <Column expander style="width: 4rem" />
                                    <Column field="name" header="Lesson name">
                                        <template #body="slotProps">
                                            <Avatar icon="pi pi-file" class="mr-2 bg-orange-600 text-white" shape="circle" />
                                            <span class="font-bold mr-3">{{slotProps.data.name}}</span>
                                            <Badge v-if="slotProps.data.chapters.length > 0" :value="String(slotProps.data.chapters.length)" />
                                        </template>
                                    </Column>
                                    <Column style="width: 200px">
                                        <template #body="slotProps">
                                            <Button icon="pi pi-cog" class="mr-2 p-button-rounded p-button-text p-button-primary text-pink-600" @click="config_chapters(slotProps)" v-tooltip.top="'Configure exercises'" />
                                            <Button icon="pi pi-eye" class="mr-2 p-button-rounded p-button-text p-button-primary text-pink-600" @click="open_url('https://sprachtechnik-app-app-100-lwnjocwfda-oa.a.run.app/'+slotProps.data.course_id+'/'+slotProps.data.booklet_id+'/'+slotProps.data.id)" v-tooltip.top="'Open lesson'" />
                                        </template>
                                    </Column>
                                    <template #expansion="slotProps">
                                        <div rounded class="overflow-hidden shadow-4 rounded border-200">
                                            <DataTable v-model:expandedRows="expanded_exercises" :value="slotProps.data.chapters" responsiveLayout="scroll" :rowHover="true">
                                                <Column expander style="width: 4rem" />
                                                <Column field="name" header="Chapter name">
                                                    <template #body="slotProps">
                                                        <Avatar icon="pi pi-bookmark" class="mr-2 bg-pink-600 text-white" shape="circle" />
                                                        <span class="font-bold mr-3">{{slotProps.data.name}}</span>
                                                        <Badge v-if="slotProps.data.exercises.length > 0" :value="String(slotProps.data.exercises.length)" />
                                                    </template>
                                                </Column>
                                                <Column style="width: 200px">
                                                    <template #body="slotProps">
                                                        <Button icon="pi pi-cog" class="mr-2 p-button-rounded p-button-text p-button-primary text-purple-600" @click="config_exercises(slotProps)" v-tooltip.top="'Configure chapters'" />
                                                        <Button icon="pi pi-eye" class="mr-2 p-button-rounded p-button-text p-button-primary text-purple-600" @click="open_url('https://sprachtechnik-app-app-100-lwnjocwfda-oa.a.run.app/'+slotProps.data.course_id+'/'+slotProps.data.booklet_id+'/'+slotProps.data.lesson_id+'/'+slotProps.data.id)" v-tooltip.top="'Open chapter'" />
                                                    </template>
                                                </Column>
                                                <template #expansion="slotProps">
                                                    <div rounded class="overflow-hidden shadow-4 rounded border-200">
                                                        <DataTable :value="slotProps.data.exercises" responsiveLayout="scroll" :rowHover="true">
                                                            <Column style="width: 50px">
                                                                <template #body="slotProps">
                                                                    <Avatar :label="'#' + String(slotProps.index + 1)" shape="circle" />
                                                                </template>
                                                            </Column>
                                                            <Column field="name" header="Exercise name" style="width: 20%">
                                                                <template #body="slotProps">
                                                                    <Avatar icon="pi pi-check-square" class="mr-2 bg-purple-600 text-white" shape="circle" />
                                                                    <span class="font-bold mr-3">{{slotProps.data.name}}</span>
                                                                </template>
                                                            </Column>
                                                            <Column field="input" header="Input" style="width: 50%">
                                                                <template #body="slotProps">
                                                                    <span>{{slotProps.data.input}}</span>
                                                                </template>
                                                            </Column>
                                                            <Column field="type" header="Type">
                                                                <template #body="slotProps">
                                                                    <Chip :label="get_exercise_type_name(slotProps.data.type)" v-if="slotProps.data.type == 'reading'" class="bg-purple-100" />
                                                                    <Chip :label="get_exercise_type_name(slotProps.data.type)" v-if="slotProps.data.type == 'info'" class="bg-blue-100" />
                                                                    <Chip :label="get_exercise_type_name(slotProps.data.type)" v-if="slotProps.data.type == 'vocabulary'" class="bg-green-100" />
                                                                    <Chip :label="get_exercise_type_name(slotProps.data.type)" v-if="slotProps.data.type == 'pronunciation'" class="bg-orange-100" />
                                                                </template>
                                                            </Column>
                                                            <Column style="width: 200px">
                                                                <template #body="slotProps">
                                                                    <Button icon="pi pi-pencil" class="mr-2 p-button-rounded p-button-text p-button-primary text-purple-600" @click="edit_exercise(slotProps)" v-tooltip.top="'Edit exercise'" />
                                                                    <Button icon="pi pi-eye" class="mr-2 p-button-rounded p-button-text p-button-primary text-purple-600" @click="open_url('https://sprachtechnik-app-app-100-lwnjocwfda-oa.a.run.app/preview/' + slotProps.data.id)" v-tooltip.top="'Open exercise'" />
                                                                </template>
                                                            </Column>
                                                        </DataTable>
                                                    </div>
                                                </template>
                                            </DataTable>
                                        </div>
                                    </template>
                                </DataTable>
                            </div>
                        </template>
                    </DataTable>
                </div>
            </template>
        </DataTable>
    </div>
    <!--------------------------------------------------------------------->
    <!---------------------- Dialog: Edit Course -------------------------->
    <!--------------------------------------------------------------------->
    <Dialog v-model:visible="edit_course_dialog" :style="{width: '80%'}" header="Edit course" :modal="true" class="p-fluid z-5">
        <div class="col-12 formgrid grid">
            <div class="field col-12"><span class="font-bold text-xl">Roles</span></div>
            <div class="field col-12">
                <DataTable :value="course.roles" class="w-full" editMode="cell" @cell-edit-complete="edit_row_roles" @cell-edit-init="init_cell">
                    <Column field="name" header="Role name">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            <span class="font-bold">{{data[field]}}</span>
                        </template>
                    </Column>
                    <Column field="language" header="Language">
                        <template #editor="{ data, field }">
                            <Dropdown v-model="data[field]" :options="languages" optionLabel="name" optionValue="code" />
                        </template>
                        <template #body="{ data, field }">
                            <span>{{get_language_name(data[field])}}</span>
                        </template>
                    </Column>
                    <Column field="voice" header="Voice">
                        <template #editor="{ data, field }">
                            <Dropdown v-model="data[field]" :options="voices" optionLabel="name" optionValue="id" />
                        </template>
                        <template #body="{ data, field }">
                            <span>{{get_voice_name(data[field])}}</span>
                        </template>
                    </Column>
                    <Column header="Preview">
                        <template #body="{ data }">
                            <Button icon="pi pi-play" class="mr-2 p-button-rounded p-button-primary" @click="play_preview(data)" v-tooltip.top="'Play audio'" />
                        </template>
                    </Column>
                    <Column field="provider" header="Provider">
                        <template #editor="{ data, field }">
                            <Dropdown v-model="data[field]" :options="providers" optionLabel="name" optionValue="code" />
                        </template>
                        <template #body="{ data, field }">
                            <span>{{data[field]}}</span>
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <Button icon="pi pi-trash" class="mr-2 p-button-rounded p-button-text p-button-danger" v-tooltip.top="'delete role'" @click="delete_role(slotProps)" />
                        </template>
                        <template #header="slotProps">
                            <Button icon="pi pi-plus" class="mr-2 p-button-rounded" v-tooltip.top="'Add role'" @click="add_role(slotProps)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="field col-12">
                <Button label="Save" :disabled="changes_course != 0 && editing == 0 ? false : true" icon="pi pi-save" class="w-auto p-button-success mr-2" @click="save_course(), edit_course_dialog = false" :badge="String(changes_course)" badgeSeverity="danger" />
                <Button label="Cancel" class="w-auto p-button-danger" @click="edit_course_dialog = false, editing = 0" />
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------------------------->
    <!-------------------- Dialog: Config booklets ------------------------>
    <!--------------------------------------------------------------------->
    <Dialog v-model:visible="config_booklets_dialog" :style="{width: '80%'}" header="Config booklets" :modal="true" class="p-fluid z-5">
        <DataTable :value="booklets" responsiveLayout="scroll" editMode="cell" @cell-edit-complete="edit_booklet" @cell-edit-init="init_cell" :rowHover="true" @rowReorder="reorder_booklets">
            <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
            <Column field="name" header="Booklet name" style="">
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" />
                </template>
                <template #body="{ data, field }">
                    <span class="font-bold">{{data[field]}}</span>
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <Button icon="pi pi-trash" class="mr-2 p-button-rounded p-button-text p-button-danger" @click="delete_booklet(slotProps.index)" v-tooltip.top="'Delete booklet'" />
                </template>
                <template #header="slotProps">
                    <Button icon="pi pi-plus" class="mr-2 p-button-rounded" @click="add_booklet(slotProps)" v-tooltip.top="'Add booklet'" />
                </template>
            </Column>
        </DataTable>
        <div class="flex justify-end mt-4">
            <Button :disabled="changes_booklets != 0 && editing == 0 ? false : true" label="Save" icon="pi pi-save" class="w-auto p-button-success mr-2" @click="save_booklets()" :badge="String(changes_booklets)" badgeSeverity="danger" />
            <Button label="Cancel" class="w-auto p-button-danger" @click="config_booklets_dialog = false, changes_booklets = 0, editing = 0" />
        </div>
    </Dialog>
    <!--------------------------------------------------------------------->
    <!-------------------- Dialog: Config lessons ------------------------->
    <!--------------------------------------------------------------------->
    <Dialog v-model:visible="config_lessons_dialog" :style="{width: '80%'}" header="Config lessons" :modal="true" class="p-fluid z-5">
        <DataTable :value="lessons" responsiveLayout="scroll" editMode="cell" @cell-edit-complete="edit_lessons" @cell-edit-init="init_cell" :rowHover="true" @rowReorder="reorder_lessons">
            <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
            <Column field="name" header="Lesson name" style="">
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" />
                </template>
                <template #body="{ data, field }">
                    <span class="font-bold">{{data[field]}}</span>
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <Button icon="pi pi-trash" class="mr-2 p-button-rounded p-button-text p-button-danger" @click="delete_lesson(slotProps.index)" v-tooltip.top="'Delete lesson'" />
                </template>
                <template #header="slotProps">
                    <Button icon="pi pi-plus" class="mr-2 p-button-rounded" @click="add_lesson(slotProps)" v-tooltip.top="'Add lesson'" />
                </template>
            </Column>
        </DataTable>
        <div class="flex justify-end mt-4">
            <Button :disabled="changes_lessons != 0 && editing == 0 ? false : true" label="Save" icon="pi pi-save" class="w-auto p-button-success mr-2" @click="save_lessons()" :badge="String(changes_lessons)" badgeSeverity="danger" />
            <Button label="Cancel" class="w-auto p-button-danger" @click="config_lessons_dialog = false, changes_lessons = 0, editing = 0" />
        </div>
    </Dialog>
    <!--------------------------------------------------------------------->
    <!------------------- Dialog: Config chapters ------------------------->
    <!--------------------------------------------------------------------->
    <Dialog v-model:visible="config_chapters_dialog" :style="{width: '80%'}" header="Config chapters" :modal="true" class="p-fluid z-5">
        <DataTable :value="chapters" responsiveLayout="scroll" editMode="cell" @cell-edit-complete="edit_chapters" @cell-edit-init="init_cell" :rowHover="true" @rowReorder="reorder_chapters">
            <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
            <Column field="name" header="Chapter name" style="">
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" />
                </template>
                <template #body="{ data, field }">
                    <span class="font-bold">{{data[field]}}</span>
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <Button icon="pi pi-trash" class="mr-2 p-button-rounded p-button-text p-button-danger" @click="delete_chapter(slotProps.index)" v-tooltip.top="'Delete lesson'" />
                </template>
                <template #header="slotProps">
                    <Button icon="pi pi-plus" class="mr-2 p-button-rounded" @click="add_chapter(slotProps)" v-tooltip.top="'Add lesson'" />
                </template>
            </Column>
        </DataTable>
        <div class="flex justify-end mt-4">
            <Button :disabled="changes_chapters != 0 && editing == 0 ? false : true" label="Save" icon="pi pi-save" class="w-auto p-button-success mr-2" @click="save_chapters()" :badge="String(changes_chapters)" badgeSeverity="danger" />
            <Button label="Cancel" class="w-auto p-button-danger" @click="config_chapters_dialog = false, changes_chapters = 0, editing = 0" />
        </div>
    </Dialog>
    <!--------------------------------------------------------------------->
    <!------------------- Dialog: Config exercises ------------------------>
    <!--------------------------------------------------------------------->
    <Dialog v-model:visible="config_exercises_dialog" :style="{width: '80%'}" header="Config exercises" :modal="true" class="p-fluid z-5">
        <DataTable :value="exercises" responsiveLayout="scroll" editMode="cell" @cell-edit-complete="edit_exercises" @cell-edit-init="init_cell" :rowHover="true" @rowReorder="reorder_exercises">
            <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
            <Column field="name" header="Exercise name" style="width: 20%">
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" />
                </template>
                <template #body="{ data, field }">
                    <span class="font-bold">{{data[field]}}</span>
                </template>
            </Column>
            <Column field="input" header="Input" style="width: 50%">
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" />
                </template>
                <template #body="{ data, field }">
                    <span>{{data[field]}}</span>
                </template>
            </Column>
            <Column field="type" header="Type">
                <template #editor="{ data, field }">
                    <Dropdown v-model="data[field]" :options="exercise_types" optionLabel="name" optionValue="code" />
                </template>
                <template #body="{ data, field }">
                    <Chip :label="get_exercise_type_name(data[field])" v-if="data[field] == 'reading'" class="bg-purple-100" />
                    <Chip :label="get_exercise_type_name(data[field])" v-if="data[field] == 'info'" class="bg-blue-100" />
                    <Chip :label="get_exercise_type_name(data[field])" v-if="data[field] == 'vocabulary'" class="bg-green-100" />
                    <Chip :label="get_exercise_type_name(data[field])" v-if="data[field] == 'pronunciation'" class="bg-orange-100" />
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <Button icon="pi pi-trash" class="mr-2 p-button-rounded p-button-text p-button-danger" @click="delete_exercise(slotProps.index)" v-tooltip.top="'Delete lesson'" />
                </template>
                <template #header="slotProps">
                    <Button icon="pi pi-plus" class="mr-2 p-button-rounded" @click="add_exercise(slotProps)" v-tooltip.top="'Add lesson'" />
                </template>
            </Column>
        </DataTable>
        <div class="flex justify-end mt-4">
            <Button :disabled="changes_exercises != 0 && editing == 0 ? false : true" label="Save" icon="pi pi-save" class="w-auto p-button-success mr-2" @click="save_exercises()" :badge="String(changes_exercises)" badgeSeverity="danger" />
            <Button label="Cancel" class="w-auto p-button-danger" @click="config_exercises_dialog = false, changes_exercises = 0, editing = 0" />
        </div>
    </Dialog>
    <!--------------------------------------------------------------------->
    <!----------------- Dialog: Edit reading exercises -------------------->
    <!--------------------------------------------------------------------->
    <Dialog v-model:visible="edit_reading_exercise_dialog" :style="{width: '80%'}" header="Config exercises" :modal="true" class="p-fluid z-5">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-4">
                <span class="p-float-label">
                    <InputText id="sentence" v-model="exercise.input" />
                    <label for="sentence">Sentence</label>
                </span>
            </div>
            <div class="field col-12">
                <Button label="1) Generate sentences" icon="pi pi-arrow-down" class="w-auto" @click="process_exercise(exercise_type, 'generate_sentences')" iconPos="right" severity="help" />
            </div>
            <div class="field col-12">
                <div rounded class="overflow-hidden shadow-4 rounded border-200">
                    <DataTable v-if="exercise_data.sentences" :value="exercise_data.sentences" responsiveLayout="scroll" editMode="cell" @cell-edit-complete="edit_sentence" @cell-edit-init="init_cell" :rowHover="true" @rowReorder="reorder_sentences">
                        <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                        <Column field="role" header="Role" style="">
                            <template #editor="{ data, field }">
                                <Dropdown v-model="data[field]" :options="roles" optionLabel="name" optionValue="name" />
                            </template>
                            <template #body="{ data, field }">
                                <span class="font-bold">{{data[field]}}</span>
                            </template>
                        </Column>
                        <Column field="sentence" header="Sentence" style="">
                            <template #editor="{ data, field }">
                                <InputText v-model="data[field]" />
                            </template>
                            <template #body="{ data, field }">
                                <span>{{data[field]}}</span>
                            </template>
                        </Column>
                        <Column>
                            <template #body="slotProps">
                                <Button icon="pi pi-trash" class="mr-2 p-button-rounded p-button-text p-button-danger" @click="delete_sentence(slotProps.index)" v-tooltip.top="'Delete lesson'" />
                            </template>
                            <template #header="slotProps">
                                <Button icon="pi pi-plus" class="mr-2 p-button-rounded" @click="add_sentence(slotProps)" v-tooltip.top="'Add sentence'" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
            <div class="field col-12">
                <Button v-if="exercise_data.sentences" :disabled="eval_reading_sentences(exercise_data.sentences) == true && editing == 0 ? false : true" label="2) Generate Word mappings" icon="pi pi-arrow-down" class="w-auto" @click="process_exercise(exercise_type, 'generate_mappings')" iconPos="right" severity="help" />
            </div>
            <div class="field col-12" v-if="exercise_data.generate_mappings">
                <div v-for="(sentence, key) in exercise_data.sentences" :key="key">
                    <Panel class="shadow-4 mt-3" :header="sentence.sentence" toggleable>
                        <div rounded class="overflow-hidden shadow-4 rounded border-200">
                            <DataTable :value="sentence.mapping" responsiveLayout="scroll" editMode="cell" @cell-edit-complete="edit_map_sentence(key, $event)" @cell-edit-init="init_cell" :rowHover="true" @rowReorder="reorder_map_sentences(key, $event)">
                                <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                                <Column field="word" header="Word" style="width: 30%">
                                    <template #editor="{ data, field }">
                                        <InputText v-model="data[field]" />
                                    </template>
                                    <template #body="{ data, field }">
                                        <span>{{data[field]}}</span>
                                    </template>
                                </Column>
                                <Column field="translation" header="Translation" style="width: 30%">
                                    <template #editor="{ data, field }">
                                        <Dropdown v-model="data[field]" editable :options="translations" optionLabel="word" optionValue="word" placeholder="Select or write a translation" class="w-full" />
                                        <InputText v-model="data[field]" />
                                    </template>
                                    <template #body="{ data, field }">
                                        <span>{{data[field]}}</span>
                                    </template>
                                </Column>
                                <Column>
                                    <template #body="slotProps">
                                        <Button icon="pi pi-trash" class="mr-2 p-button-rounded p-button-text p-button-danger" @click="delete_map_sentence(key, slotProps)" />
                                    </template>
                                    <template #header="slotProps">
                                        <Button icon="pi pi-plus" class="mr-2 p-button-rounded" @click="add_map_sentence(key, slotProps)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </Panel>
                </div>
            </div>
            <div class="field col-12">
                <Button v-if="exercise_data.generate_mappings" :disabled="eval_reading_mappings(exercise_data.sentences) == true && editing == 0 ? false : true" label="3) Generate Audio" icon="pi pi-arrow-down" class="w-auto" iconPos="right" severity="help" @click="process_exercise(exercise_type, 'generate_audio')" />
            </div>
            <div class="field col-12" v-if="exercise_data.generate_audio">
                <div v-for="(sentence, key) in exercise_data.sentences" :key="key">
                    <Panel class="shadow-4 mt-3" :header="sentence.sentence" toggleable>
                        <div class="flex justify-start">
                            <Button class="p-button-rounded bg-primary-500 mr-2" icon="pi pi-play" @click="play_audio(sentence.audio_url)" />
                            <Dropdown v-model="playback_rate" :options="playback_rates" optionLabel="name" optionValue="value" class="mr-2 w-10rem" placeholder="Playback rate" @change="save_playback_rate" />
                        </div>
                    </Panel>
                </div>
            </div>
        </div>
        <div class="flex justify-end mt-4">
            <Button :disabled="exercise_data.generate_audio && editing == 0 ? false : true" label="Save" icon="pi pi-save" class="w-auto p-button-success mr-2" @click="save_exercise(), edit_reading_exercise_dialog = false" />
            <Button label="Cancel" class="w-auto p-button-danger" @click="edit_reading_exercise_dialog = false, editing = 0" />
        </div>
    </Dialog>
    <!--------------------------------------------------------------------->
    <!------------------ Dialog: Edit info exercises ---------------------->
    <!--------------------------------------------------------------------->
    <Dialog v-model:visible="edit_info_exercise_dialog" :style="{width: '80%'}" header="Config exercises" :modal="true" class="p-fluid z-5">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-4">
                <Textarea id="sentence" v-model="exercise_data.input" />
            </div>
            <div class="field col-12">
                <Button label="Translate with AI" icon="pi pi-language" class="w-auto mr-2" @click="process_exercise(exercise_type, 'info_generate_translation')" severity="help" />
                <Button label="Optimize with AI" icon="pi pi-star-fill" class="w-auto mr-2" @click="process_exercise(exercise_type, 'info_optimize_text')" severity="help" />
                <Button label="Copy Input" icon="pi pi-copy" class="w-auto mr-2" @click="process_exercise(exercise_type, 'info_copy_input')" severity="help" />
            </div>
            <div class="field col-12">
                <Editor editorStyle="width: 100%; min-height: 350px;" v-model="exercise_data.text">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                            <button class="ql-image" v-tooltip.bottom="'Bild hochladen'"></button>
                        </span>
                    </template>
                </Editor>
            </div>
            <template v-if="exercise_data.info_generate_translation || exercise_data.info_optimize_text || exercise_data.info_copy_input">
                <div class="field col-4">
                    <Dropdown v-model="exercise_data.role" :options="exercise_data.roles" optionLabel="name" optionValue="name" class="mr-2" placeholder="Choose role" />
                </div>
                <div class="field col-8">
                    <Button :disabled="exercise_data.text && editing == 0 && exercise_data.role ? false : true" label="Generate Audio" icon="pi pi-microphone" class="w-auto" severity="help" @click="process_exercise(exercise_type, 'info_generate_audio')" />
                </div>
            </template>
            <div class="field col-12" v-if="exercise_data.info_generate_audio">
                <Panel class="shadow-4 mt-3" header="Audio" toggleable>
                    <div class="flex justify-start">
                        <Button class="p-button-rounded bg-primary-500 mr-2" icon="pi pi-play" @click="play_audio(exercise_data.audio_url)" />
                        <Dropdown v-model="playback_rate" :options="playback_rates" optionLabel="name" optionValue="value" class="mr-2 w-10rem" placeholder="Playback rate" @change="save_playback_rate" />
                    </div>
                </Panel>
            </div>
            <div class="field col-12" v-if="exercise_data.info_generate_audio">
                <Panel class="shadow-4 mt-3" header="Image (optional)" toggleable>
                    <div class="flex justify-start align-items-center">
                        <Image preview :src="exercise_data.image_url" class="w-12rem shadow-2 mr-2" />
                        <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Exercise image" customUpload @uploader="vocab_file_upload($event)" />
                    </div>
                </Panel>
            </div>
            <div class="flex justify-end mt-4">
                <Button :disabled="exercise_data.info_generate_audio && editing == 0 ? false : true" label="Save" icon="pi pi-save" class="w-auto p-button-success mr-2" @click="save_exercise(), edit_info_exercise_dialog = false" />
                <Button label="Cancel" class="w-auto p-button-danger" @click="edit_info_exercise_dialog = false, editing = 0" />
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------------------------------->
    <!------------------ Dialog: Edit vocabulary exercises ---------------------->
    <!--------------------------------------------------------------------------->
    <Dialog v-model:visible="edit_vocabulary_exercise_dialog" :style="{width: '80%'}" header="Config exercises" :modal="true" class="p-fluid z-5">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-4">
                <span class="p-float-label">
                    <InputText id="sentence" v-model="exercise_data.input" />
                    <label for="sentence">Input (Comma separated)</label>
                </span>
            </div>
            <div class="field col-12">
                <Button :disabled="exercise_data.input ? false : true" label="1) Generate vocabs" icon="pi pi-arrow-down" class="w-auto" @click="process_exercise(exercise_type, 'vocabs_generate_table')" iconPos="right" severity="help" />
            </div>
            <div class="field col-12">
                <div rounded class="overflow-hidden shadow-4 rounded border-200">
                    <DataTable :value="exercise_data.vocabs" responsiveLayout="scroll" editMode="cell" @cell-edit-complete="edit_vocab" @cell-edit-init="init_cell" :rowHover="true" @rowReorder="reorder_vocabs">
                        <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                        <Column field="consonant" header="Consonant" style="">
                            <template #editor="{ data, field }">
                                <InputText v-model="data[field]" />
                            </template>
                            <template #body="{ data, field }">
                                <span class="font-bold">{{data[field]}}</span>
                            </template>
                        </Column>
                        <Column field="sentence" header="Sentence" style="">
                            <template #editor="{ data, field }">
                                <InputText v-model="data[field]" />
                            </template>
                            <template #body="{ data, field }">
                                <span>{{data[field]}}</span>
                            </template>
                        </Column>
                        <Column field="sentence_translation" header="Translation" style="">
                            <template #editor="{ data, field }">
                                <InputText v-model="data[field]" />
                            </template>
                            <template #body="{ data, field }">
                                <span>{{data[field]}}</span>
                            </template>
                        </Column>
                        <Column>
                            <template #body="slotProps">
                                <Button icon="pi pi-trash" class="mr-2 p-button-rounded p-button-text p-button-danger" @click="delete_vocab(slotProps.index)" v-tooltip.top="'Delete vocab'" />
                            </template>
                            <template #header="slotProps">
                                <Button icon="pi pi-plus" class="mr-2 p-button-rounded" @click="add_vocab(slotProps)" v-tooltip.top="'Add vocab'" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
            <template v-if="eval_vocab_table(exercise_data.vocabs) && exercise_data.vocabs.length > 0">
                <div class="field col-4">
                    <Dropdown v-model="exercise_data.role" :options="exercise_data.roles" optionLabel="name" optionValue="name" class="mr-2" placeholder="Choose role" />
                </div>
                <div class="field col-8">
                    <Button :disabled="eval_vocab_table(exercise_data.vocabs) && exercise_data.vocabs.length > 0 && editing == 0 && exercise_data.role ? false : true" label="Generate Audio" icon="pi pi-microphone" class="w-auto" severity="help" @click="process_exercise(exercise_type, 'vocabs_generate_audio')" />
                </div>
            </template>
            <div class="field col-12" v-if="exercise_data.vocab_generate_audio">
                <Panel class="shadow-4 mt-3" header="Audio" toggleable>
                    <div class="flex justify-start">
                        <Button class="p-button-rounded bg-primary-500 mr-2" icon="pi pi-play" @click="play_audio(exercise_data.audio_url)" />
                        <Dropdown v-model="playback_rate" :options="playback_rates" optionLabel="name" optionValue="value" class="mr-2 w-10rem" placeholder="Playback rate" @change="save_playback_rate" />
                    </div>
                </Panel>
            </div>
            <div class="flex justify-end mt-4">
                <Button :disabled="exercise_data.vocab_generate_audio && editing == 0 ? false : true" label="Save" icon="pi pi-save" class="w-auto p-button-success mr-2" @click="save_exercise(), edit_vocabulary_exercise_dialog = false" />
                <Button label="Cancel" class="w-auto p-button-danger" @click="edit_vocabulary_exercise_dialog = false, editing = 0" />
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------------------------------->
    <!-------------------- Dialog: Edit Pronunciation exercises ----------------------->
    <!--------------------------------------------------------------------------->
    <Dialog v-model:visible="edit_pronunciation_exercise_dialog" :style="{width: '80%'}" header="Config lecture exercises" :modal="true" class="p-fluid z-5">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-4">
                <Textarea id="sentence" v-model="exercise_data.input" />
            </div>
            <div class="field col-12">
                Instructions
            </div>
            <div class="field col-12">
                <Editor editorStyle="width: 100%; min-height: 350px;" v-model="exercise_data.instructions">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                            <button class="ql-image" v-tooltip.bottom="'Bild hochladen'"></button>
                        </span>
                    </template>
                </Editor>
            </div>
            <template v-if="exercise_data.instructions">
                <div class="field col-4">
                    <Dropdown v-model="exercise_data.instructions_role" :options="exercise_data.roles" optionLabel="name" optionValue="name" class="mr-2" placeholder="Choose instructions role" />
                </div>
                <div class="field col-8">
                    <Button :disabled="exercise_data.instructions && editing == 0 && exercise_data.instructions_role ? false : true" label="Generate Audio" icon="pi pi-microphone" class="w-auto" severity="help" @click="process_exercise(exercise_type, 'pronunciation_generate_instructions_audio')" />
                </div>
            </template>
            <div class="field col-12" v-if="exercise_data.pronunciation_generate_instructions_audio">
                <Panel class="shadow-4 mt-3" header="Audio" toggleable>
                    <div class="flex justify-start">
                        <Button class="p-button-rounded bg-primary-500 mr-2" icon="pi pi-play" @click="play_audio(exercise_data.instructions_audio_url)" />
                        <Dropdown v-model="playback_rate" :options="playback_rates" optionLabel="name" optionValue="value" class="mr-2 w-10rem" placeholder="Playback rate" @change="save_playback_rate" />
                    </div>
                </Panel>
            </div>
            <div class="field col-12">
                <Button :disabled="exercise_data.input && exercise_data.pronunciation_generate_instructions_audio ? false : true" label="Generate vocabs" icon="pi pi-arrow-down" class="w-auto" @click="process_exercise(exercise_type, 'vocabs_generate_table')" iconPos="right" severity="help" />
            </div>
            <div class="field col-12" v-if="exercise_data.pronunciation_generate_instructions_audio">
                <div rounded class="overflow-hidden shadow-4 rounded border-200">
                    <DataTable :value="exercise_data.vocabs" responsiveLayout="scroll" editMode="cell" @cell-edit-complete="edit_vocab" @cell-edit-init="init_cell" :rowHover="true" @rowReorder="reorder_vocabs">
                        <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                        <Column field="sentence" header="Sentence / word" style="">
                            <template #editor="{ data, field }">
                                <InputText v-model="data[field]" />
                            </template>
                            <template #body="{ data, field }">
                                <span>{{data[field]}}</span>
                            </template>
                        </Column>
                        <Column field="sentence_translation" header="Translation" style="">
                            <template #editor="{ data, field }">
                                <InputText v-model="data[field]" />
                            </template>
                            <template #body="{ data, field }">
                                <span>{{data[field]}}</span>
                            </template>
                        </Column>
                        <Column>
                            <template #body="slotProps">
                                <Button icon="pi pi-trash" class="mr-2 p-button-rounded p-button-text p-button-danger" @click="delete_vocab(slotProps.index)" v-tooltip.top="'Delete vocab'" />
                            </template>
                            <template #header="slotProps">
                                <Button icon="pi pi-plus" class="mr-2 p-button-rounded" @click="add_vocab(slotProps)" v-tooltip.top="'Add vocab'" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
            <template v-if="eval_vocab_table_2_columns(exercise_data.vocabs) && exercise_data.vocabs.length > 0">
                <div class="field col-4">
                    <Dropdown v-model="exercise_data.vocab_role" :options="exercise_data.roles" optionLabel="name" optionValue="name" class="mr-2" placeholder="Choose role" />
                </div>
                <div class="field col-8">
                    <Button :disabled="eval_vocab_table_2_columns(exercise_data.vocabs) && exercise_data.vocabs.length > 0 && editing == 0 && exercise_data.vocab_role ? false : true" label="Generate Audio" icon="pi pi-microphone" class="w-auto" severity="help" @click="process_exercise(exercise_type, 'vocabs_generate_audio')" />
                </div>
            </template>
            <div class="field col-12" v-if="exercise_data.vocab_generate_audio">
                <Panel class="shadow-4 mt-3" header="Audio" toggleable>
                    <div class="flex justify-start">
                        <Button class="p-button-rounded bg-primary-500 mr-2" icon="pi pi-play" @click="play_audio(exercise_data.vocab_audio_url)" />
                        <Dropdown v-model="playback_rate" :options="playback_rates" optionLabel="name" optionValue="value" class="mr-2 w-10rem" placeholder="Playback rate" @change="save_playback_rate" />
                    </div>
                </Panel>
            </div>
            <div class="flex justify-end mt-4">
                <Button :disabled="exercise_data.vocab_generate_audio && editing == 0 ? false : true" label="Save" icon="pi pi-save" class="w-auto p-button-success mr-2" @click="save_exercise(), edit_info_exercise_dialog = false" />
                <Button label="Cancel" class="w-auto p-button-danger" @click="edit_info_exercise_dialog = false, editing = 0" />
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------------------------------->
    <!----------------------------- Dialog: New Course -------------------------->
    <!--------------------------------------------------------------------------->
    <Dialog v-model:visible="new_course_dialog" :style="{width: '80%'}" header="Create new course" :modal="true" class="p-fluid z-5">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-4">
                <span class="p-float-label">
                    <InputText id="name" v-model="new_course.name" />
                    <label for="name">Course name</label>
                </span>
            </div>
            <div class="field col-12">
                <Button label="Save" icon="pi pi-save" class="w-auto p-button-success mr-2" @click="save_new_course()" />
                <Button label="Cancel" class="w-auto p-button-danger" @click="new_course_dialog = false" />
            </div>
        </div>
    </Dialog>
</template>